import React from "react"
// import { useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { getCookie } from "../../Utilities"

import Card from "./Card"
import CardGreyedOut from "./CardGreyedOut"
import IconBack from "../../icons/IconBack"
import './CardPage.css'

const CardPage = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  return (
    <>
      <div id="interview-background"></div>
      <div id="interview" className="flex h-screen">
        <div className="m-auto">
          <div id="cards-outer-container" className="px-8">
            {/* Back Button */}
            {props.interview.event_list && props.interview.event_list.length > 0 && props.interview.event_list[0] !== process.env.REACT_APP_FIRST_SCREEN &&
              <div className="hidden md:block mt-8 mb-4">
                <a id="back-button" className="text-brandBlue text-lg" href="#/" onClick={(e) => props.goPrevQuestion(e)}>
                  <IconBack className="inline stroke-width-2-25 text-brandBlue h-5 w-5 mr-2" />
                  {translate("prev-question-txt")}
                </a>
              </div>
            }

            {/* "Cards */}
            <div className="flex flex-wrap -mx-4">
              { props.interview.fields &&
                props.interview.fields.length > 0 &&
                props.interview.fields[0].choices &&
                props.interview.fields[0].choices.length > 0 &&
                props.isLoadedInApp === null &&
                props.interview.fields[0].choices.map((interviewObj, index) => (
                  <Card
                    isLoadedInApp={props.isLoadedInApp}
                    showButtonSpinner={props.showButtonSpinner}
                    key={index}
                    index={index}
                    name={props.interview.fields[0].variable_name || interviewObj.variable_name}
                    label={interviewObj.label}
                    text={interviewObj.help}
                    value={interviewObj.value}
                    handleClick={props.handleClick}
                  />
              ))}

              { props.interview.fields &&
                props.interview.fields.length > 0 &&
                props.interview.fields[0].choices &&
                props.interview.fields[0].choices.length > 0 &&
                props.isLoadedInApp !== null &&
                props.interview.fields[0].choices.map((interviewObj, index) => (
                  <CardGreyedOut
                    isLoadedInApp={props.isLoadedInApp}
                    showButtonSpinner={props.showButtonSpinner}
                    key={index}
                    index={index}
                    name={props.interview.fields[0].variable_name || interviewObj.variable_name}
                    label={interviewObj.label}
                    text={interviewObj.help}
                    value={interviewObj.value}
                    handleClick={props.handleClick}
                  />
              ))}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default CardPage;
