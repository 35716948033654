import React from "react"
// import { useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { getCookie } from "../../Utilities"

const CardGreyedOut = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  return (
    <>
    <div key={props.index} className="w-full md:w-1/2 xl:w-1/3 p-4">
      <div className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden h-full">
        <div className="bg-gradient-to-r from-brandBlue via-brandBlueDark to-gray-800">
          <h1 className="py-4 mb-2 text-white text-center text-lg">{props.label}</h1>
        </div>
        <div className="card-text px-6 pt-4 pb-7 text-brandTextDark lg:text-md">
            { <div dangerouslySetInnerHTML={{__html: props.text}} /> }
        </div>
        <div className="px-4 bg-gray-500">
          <button
            id={props.name + "_" + props.index}
            className="px-2 py-4 text-white text-md"
            name={props.name}
            value={props.value}>
              {translate("find-out-more-txt")}
              <div className={"ml-8 mr-6 dot-pulse  " + (props.showButtonSpinner && props.isLoadedInApp === props.name + "_" + props.index ? 'inline-block' : 'hidden')}>
              </div>
          </button>
        </div>
      </div>
    </div>
    </>
  )
}

export default CardGreyedOut;
