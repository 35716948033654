import * as React from "react";

function IconQLMentalHealthForMobile(props) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2.15"
      aria-hidden="true"
      {...props}
    >
      <path d="m22.789 22.79-6.1886-6.1886m0 0a8.9314 8.9314 0 1 0-12.631-12.631 8.9314 8.9314 0 0 0 12.631 12.631z"/>
    </svg>
  )
}

export default IconQLMentalHealthForMobile
