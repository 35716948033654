import React from "react"
import { classNames } from "../Utilities"

import './Interview.css'
import './ApplicationButton.css'

const ApplicationButton = (props) => {
  const determineIfExtraLong = (fieldLabel) => {
    if (fieldLabel.length > 40) {
      return "extra-wide-button"
    } else {
      if (fieldLabel.length < 5) {
        return "extra-thin-button"
      } else {
        return ""
      }
    }
  }

  // button on first presentation, before sunmission
  if (props.isLoadedInApp === null) {
    return (
      <button key = {props.index}
      id={props.id}
      className={classNames(
        'mt-3 bg-brandBlue hover:bg-brandBlueDark text-white px-6 py-4 rounded-md shadow-md lg:font-medium text-md font-medium mr-4',
        props.buttonColor,
        determineIfExtraLong(props.label)
      )}
      onClick={(e) => {props.handleClick(e)}}
      name={props.name}
      value={props.value}>
        {props.label}
      </button>
    )
  } else {
  // when submitting form
    if ( props.showButtonSpinner && props.isLoadedInApp === props.id ) {

      // button clicked
      return (
        <button key = {props.index}
        id={props.id}
        disabled
        className={classNames(
          'mt-3 bg-gray-500 text-white py-4 rounded-md shadow-md lg:font-medium text-md font-medium mr-4',
          determineIfExtraLong(props.label)
        )}
        style = {{ "paddingLeft": "24px", "paddingRight": "18px"}}
        onClick={(e) => {props.handleClick(e)}}
        name={props.name}
        value={props.value}>
          <div className={classNames(
           "inline-block bg-gradient-to-r from-gray-100 via-gray-400 bg-gray-500 bg-clip-text text-transparent"
          )}
            >{props.label}
            <div className = "dot-pulse-wrapper-div inline">
              <div className="dot-pulse inline-block"></div>
            </div>
          </div>
        </button>
      )

    } else {
      // button not clicked
      return (
        <button key = {props.index}
        id={props.id}
        disabled
        className={classNames(
          'mt-3 bg-gray-500 text-white px-6 py-4 rounded-md shadow-md lg:font-medium text-md font-medium mr-4',
          determineIfExtraLong(props.label)
        )}
        onClick={(e) => {props.handleClick(e)}}
        name={props.name}
        value={props.value}>
          <div className="text-white inline">
            {props.label}
          </div>
        </button>
      )
    }
  }
}

export default ApplicationButton;
