import * as React from "react";

function IconQLInterpretForMobile(props) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      aria-hidden="true"
      {...props}
    >
      <path d="m21.754 7.5193c1.0452 0.33593 1.7735 1.3343 1.7735 2.4804v5.0697c0 1.3437-1.0015 2.484-2.3411 2.594-0.402 0.03194-0.804 0.06151-1.206 0.08517v3.6562l-3.5471-3.5486c-1.6009 0-3.1853-0.06506-4.7531-0.19281a2.5007 2.5017 0 0 1-0.97544-0.28625m11.049-9.8579a2.5137 2.5147 0 0 0-0.5628-0.11237 57.51 57.534 0 0 0-9.5156 0c-1.3372 0.11119-2.3363 1.2503-2.3363 2.5928v5.0697c0 0.99005 0.54388 1.8689 1.3656 2.3078m11.049-9.8579v-2.2167c0-1.9174-1.3621-3.5793-3.2633-3.8265a57.291 57.315 0 0 0-7.3779-0.47551c-2.5007 0-4.9635 0.16205-7.3779 0.47551-1.9012 0.24722-3.2633 1.9091-3.2633 3.8265v7.3645c0 1.9174 1.3621 3.5793 3.2633 3.8265 0.68222 0.08871 1.368 0.1656 2.0573 0.22947v5.5689l4.9127-4.9148"/>
    </svg>
  )
}

export default IconQLInterpretForMobile
