import React, {useEffect, useRef} from "react"
import RegularInterview from "../RegularInterview"
import BackLink from "../../BackLink"

import '../Interview.css'
import './InterviewWithImage.css'
import './InterviewWithVideo.css'

const InterviewWithVideo = (props) => {

  const videoDuplicated = useRef(false)
  const duplicateVideo = (screen_name) => {
    // get HTML of original video
    const videoDiv = document.querySelector('#text-section.video-orig #interview-outer-container > div.interview-body > div > div')
    let targetHTML = videoDiv.innerHTML.toString()
    targetHTML = targetHTML.replace('="1', '="2')
    targetHTML = targetHTML.replace('=1', '=2')
    targetHTML = targetHTML.replace(' = "1', ' = "2')
    targetHTML = targetHTML.replace(' = 1', ' = 2')
    // duplaicate video in left panel
    const targetDiv = document.querySelector('#video-section-wrapper > div')
    targetDiv.innerHTML = targetHTML
  }

  // Set resize listener
  // hhttps://codesandbox.io/p/sandbox/debounce-for-window-resize-j9xf6e?file=%2Fsrc%2FApp.js%3A14%2C31
  const resizeHandler = () => {
    const windowDimensions =  {"width": window.innerWidth, "height": window.innerHeight}
    // in line with CSS file , (min-width: 1024px) and (max-height: 768px)
    if (windowDimensions.width > 1023 && windowDimensions.height < 769) {
      // console.log('video in LEFT panel')
      // console.log('Closing original video/ when in full screen')
      stopVideo(document.querySelector('#interview-outer-container > div.interview-body > div > div'))
    } else {
      // console.log('video in RIGHT panel')
      // console.log('Closing duplicated video/ left panel')
      stopVideo(document.querySelector('#video-section-wrapper > div.heroSection'))
    }
  }

  const debounceFunction = (callback, delay) => {
    let timer;
    return () => {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => callback(), delay)
    }
  }

  // Stop video playing
  // https://gist.github.com/cferdinandi/9044694
  const stopVideo = (element) => {
  	const iframeElement = element.querySelector( 'iframe')
  	if ( iframeElement ) {
  		const iframeSrc = iframeElement.src;
  		iframeElement.src = iframeSrc;
  	}
  }

  useEffect(() => {
    const debounceWrapper = debounceFunction(resizeHandler, 400);
    window.addEventListener("resize", debounceWrapper);
    return () => window.removeEventListener("resize", debounceWrapper)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (!videoDuplicated.current) {
      duplicateVideo()
      videoDuplicated.current = true
    }
  }, [videoDuplicated])

  return (
    <>
      <div className="flex">
        <div id="video-section-wrapper" className="flex">
          <div className="heroSection w-full aspect-[16/9]"></div>
        </div>

        <div id="text-section" className="video-orig w-full">

          {/* Regular Interview */}
          <div id="interview-background"></div>
          <div id="interview" className="flex">
            <div className="m-auto">

              {/* Back Button */}
              {props.interview.event_list && props.interview.event_list.length > 0 && props.interview.event_list[0] !== process.env.REACT_APP_FIRST_SCREEN &&
              <div className="back-link hidden md:my-4 xl:mx-0 md:block min-w-screen">
                <BackLink goPrevQuestion = {props.goPrevQuestion} />
              </div>
              }

              <div id="interview-outer-container"
              className="px-4 mx-0 md:p-6 md:mx-auto lg:p-10 md:rounded-md md:bg-white md:shadow-lg w-screen max-w-screen-md">
                <RegularInterview
                  isLoadedInApp={props.isLoadedInApp}
                  showButtonSpinner={props.showButtonSpinner}
                  interview={props.interview}
                  sessionID={props.sessionID}
                  handleClick={props.handleClick}
                  goPrevQuestion = {props.goPrevQuestion}
                  restartInterview = {props.restartInterview}
                  screenType = "regular"
                />
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default InterviewWithVideo;
