import * as React from "react";

function IconQLLegalForMobile(props) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="1.8"
      aria-hidden="true"
      {...props}
    >
      <path d="m12.028 1.3921v19.88c-1.7931 0-3.5106 0.3054-5.0978 0.86434m5.0978-0.86434c1.7931 0 3.5106 0.3054 5.0978 0.86434m3.1244-18.474a58.976 55.797 0 0 0-8.2222-0.54166c-2.7907 0-5.5363 0.18439-8.2222 0.54166m16.444 0c1.2303 0.1648 2.4484 0.36533 3.6543 0.59928m-3.6543-0.59928 3.1914 12.361c0.14861 0.57508-0.12912 1.1847-0.71746 1.3853a7.294 6.9009 0 0 1-2.474 0.40566 7.294 6.9009 0 0 1-2.474-0.40566c-0.58835-0.20053-0.86607-0.81018-0.71868-1.3853l3.1927-12.36zm-20.099 0.59928c1.2059-0.23395 2.424-0.43448 3.6543-0.59928m0 0 3.1914 12.361c0.14861 0.57508-0.12912 1.1847-0.71747 1.3853a7.2952 6.9021 0 0 1-2.474 0.40566 7.2952 6.9021 0 0 1-2.474-0.40566c-0.58835-0.20053-0.86607-0.81018-0.71868-1.3853l3.1927-12.36z"/>
    </svg>
  )
}

export default IconQLLegalForMobile
