import React, {useContext} from "react"
import InterviewContext from "./InterviewContext"

import { ArrowRightCircleIcon } from '@heroicons/react/20/solid'
import "./QuickExitButton.css"
import "./VietnameseLang.css"

const QuickExitButton = (props) => {
  const { dispatch } = useContext(InterviewContext)
  return (
    <a href="#/"
    className={`quick-exit z-20 text-brandPurple hover:text-brandPurpleDark hover:bg-brandYellowDark xl:px-3 lg:px-4 py-4 md:py-3 lg:py-4 rounded-md text-md shadow-slate-600 md:shadow-brandPurpleDark shadow-md md:shadow-lg
      ${(props.lang === 'vi') ? 'qe-vietnamese' : ''}`}
    onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({ type: 'UPDATE_NEXT_STATE', nextState: 'quickExit' })
      }}
    >
      <span>
        <ArrowRightCircleIcon
          className="inline h-6 w-6 mr-1 align-bottom" aria-hidden="true" />
      </span>
      {props.buttonText}
    </a>
   )
}

export default QuickExitButton;
