const translationsEn = {
  // ***************
  // * Framework *
  // ***************
  "web-app-title": "Pathways to Wellbeing",
  "exit-help-modal-txt": "Got it, thanks.",
  "exit-generic-modal-txt": "OK",
  "app-exit-message": "Pathways to Wellbeing.",
  "app-exit-thankyou": "<p class='mb-2'>You can use the pathway again if you need to look something else up.</p>",
  "questions": "Navigation",
  "prev-question-txt": "Previous screen",
  "prev-question-explain": "Go back to the previous screen.",
  "back-selection-txt": "Back to search screen",
  "back-selection-explain": "Go back to the search screen.",
  "quick-links": "Quick Links",
  "ql-mh-find": "Find mental health services",
  "ql-mh-find-explain": "Quickly find free or low-cost mental health services.",
  "ql-legal-find": "Find legal services",
  "ql-legal-find-explain": "Quickly find free or affordable legal services.",
  "ql-interpreter-find": "Find interpreting services",
  "ql-interpreter-find-explain": "Quickly find out when and how to ask for free interpreting.",
  "find-out-more-txt": "Find out more",
  "reload-txt": "Restart",
  "reload-explain": "Go through the guided pathway again from the start.",
  "main-menu-txt": "Main Menu",
  "main-menu-explain": "Go back to the main menu.",
  "da-main-menu": "Main Menu",
  "web-site-txt": "Go to main AVWA site",
  "about": "About",
  "about-app": "About Pathways to Wellbeing",
  "about-app-desc": "Find out more about this guided pathway.",
  "privacy-txt": "Privacy",
  "privacy-desc": "Get a better understanding of how we safeguard your privacy.",
  "website-terms": "Website Terms",
  "website-terms-desc": "Read about the terms that apply on our website and the services we provide.",
  "urgent-help": "Urgent Help",
  "restart-confirm-header":"Restart",
  "restart-confirm-text":"Are you sure you want to go through the guided pathway from the start?",
  "yes-answer": "Yes",
  "no-answer": "No",
  "OK-answer": "OK",
  "quick-exit": "Quick exit",
  "quick-exit-short": "Exit",
  "continue-txt": "Continue",
  "previous-label": "Previous",
  "next-label": "Next",
  "phone": "Phone",
  "email": "Email",
  "website": "Website",
  "website-link": "Website",
  "address": "Address",
  "service-type": "Service type",
  "walkins-badge-label": "Walk-ins",
  "more-about": "More about",
  "mht-label": "Mental Health Tribunal",
  "treatment-orders-label": "Treatment Orders",
  "family-law-label": "Family Law",
  "criminal-law-label": "Criminal Law",
  "immigration-law-label": "Immigration and asylum",
  "tenancy-law-label": "Tenancy",
  "guardianship_adm-law-label": "Guardianship and Administration",
  "ndis-label": "NDIS",
  "debts-label": "Debts",
  "vietnamese-label": "Services in Vietnamese and English",
  "walkins-available": "No appointment needed<span class=\"hidden md:inline\">, you can walk in</span>",
  "telehealth-badge-label": "Telehealth",
  "telehealth": "Phone or video<span class=\"hidden md:inline\"> (Telehealth)</span> appointments<span class=\"hidden md:inline\"> available</span>",

  // ***************
  // * Application *
  // ***************
  "alt-message-for-brand": "AVWA",
  "app-name-top-menu-item": "Pathways to Wellbeing",
  "app-name-top-mi-short": "Pathway",
  "menu-header": "Menu",

  // **************************
  // * Application Long Texts *
  // **************************
  // Urgent Help
  "urgent-help-text": `
    <p class="urgent-help text-brandOrangeDarker">
      Help available 24 hours x 7 days a week:
    </p>
    <p class="urgent-help">
      <b>Emergency, police and ambulance</b><br/>
      Call: <a href="tel:000">000</a><br/>
    </p>
    <p class="urgent-help">
      <b>Lifeline</b> - crisis support<br/>
      Call: <a href="tel:131114">13 11 14</a><br/>
      <a class="external-link" href="https://www.lifeline.org.au" target="_blank">
        lifeline.org.au
      </a>
    </p>
    <p class="urgent-help">
      <b>Suicide Callback Service</b><br/>
      Call: <a href="tel:1300659467">1300 659 467</a><br/>
      <a class="external-link" href="https://www.suicidecallbackservice.org.au" target="_blank">
        suicidecallbackservice.org.au
      </a>
    </p>
    <p class="urgent-help">
      <b>SuicideLine Victoria</b><br/>
      Call: <a href="tel:1300651251">1300 651 251</a><br/>
      <a class="external-link" href="https://suicideline.org.au" target="_blank">
        suicideline.org.au
      </a>
    </p>
    <p class="urgent-help">
      <b>1800 RESPECT</b> - Family violence and sexual assault help line<br/>
      Call: <a href="tel:1800737732">1800 737 732</a><br/>
      <a class="external-link" href="https://1800respect.org.au" target="_blank">
        1800respect.org.au
      </a>
    </p>
    <p class="urgent-help">
      <b>Gambler's Help</b><br/>
      Call: <a href="tel:1800858858">1800 858 858</a><br/>
      <a class="external-link" href="https://gamblershelp.com.au" target="_blank">
        gamblershelp.com.au
      </a>
    </p>
    <p class="urgent-help pb-0">
      If you do not speak English, you can call these services and ask for an interpreter.
      The interpreter is free.
    </p>
    <p class="urgent-help pb-0">
      Or you can call the free <b>Translation and Interpreting Service (TIS)</b>
      on <a href="tel:131450">131&nbsp;450</a>:
      <ul class="text-brandOrangeDarker ml-4" style="list-style-type:square;">
      <li><span class="text-brandTextDark">say your language</span></li>
      <li><span class="text-brandTextDark">ask to contact the service you need.</span></li>
      </ul>
    </p>`,

  // *******************************
  // * Mental Health Service Types *
  // *******************************
  // Mental Health and Wellbeing Local service
  "mh-and-wellbeing-local": `
    <p class="mb-2">
      <b>Mental Health and Wellbeing Local</b> are <b>free</b> services to help people
      with mental health challenges. They give free treatment, care and support.
    </p>
    <p class="mb-2">
      You do not need a doctor’s referral, you do not
      need a mental health care plan or a Medicare card to use the service.
    </p>
    <p class="mb-0">
      Family members, carers, friends or supporters can also get <b>free</b> help for
      themselves.
    </p>`,

    // Primary Health Network
    "phn": `
      <p class="mb-2">
        <b>Primary Health Networks (PHNs)</b> help people find mental health services
        close to them. They link different services, like hospitals, doctors,
        aged care facilities, and make sure people are getting the care they need.
        They also provide information and resources.
      </p>
      <p class="mb-0">
        There are 6 Primary Health Networks in Victoria each covering a different
        region.
      </p>`,

    // Mental Health and Wellbeing Hub
    "mh-and-wellbeing-hub": `
      <p class="mb-2">
        <b>Mental Health and Wellbeing Hubs</b> are free services that help
        people with mental health challenges. You don’t need a Medicare card
        or doctor referral. You can call to make an appointment or just walk in.
      </p>
      <p class="mb-0">
        Mental Health and Wellbeing Hubs are being replaced by Mental Health and
        Wellbeing Local services, but they continue to operate in areas where
        Local services are not available yet.
      </p>`,

    // headspace
    "headspace": `
      <p class="mb-2">
        <b>headspace</b> helps young people aged 12-25 with their mental health.
        They offer free online and phone help, as well as free or low-cost
        services.
      </p>
      <p class="mb-0">
        They also help family members and carers of people with mental health challenges.
      </p>`,

    // Family and Carer-Led Centre and Mental Health and Wellbeing Connect Centre
    "connect-centre": `
      <p class="mb-2">
        <b>Mental Health and Wellbeing Connect centres</b> are places where family
        members, carers and supporters of people with mental health challenges can go
        for support.
      </p>
      <p class="mb-0">
        They are free and anyone can visit and are run by people who have lived
        experience. They can give information, help find resources, and access
        to hardship money.
      </p>`,

    // Community Mental Health Clinic
    "community-mh-clinic": `
      <p class="mb-2">
        Saltwater Clinic is an adult community mental health clinic,
        providing mental health community care for people living in the
        Maribyrnong and Hobsons Bay municipalities. Saltwater Clinic is an
        appointment-only service.
      </p>
      <p class="mb-0">
        Referrals are through the Mercy Mental Health Triage.
      </p>`,

  // *********
  // * Pages *
  // *********
  // Privacy Policy
  "privacy-heading": "Australian Vietnamese Women’s Association Privacy Policy",
  "privacy-text": `
    <div class="static-conent font-serif">
    <p class="my-2">
      Australian Vietnamese Women’s Association (AVWA) is committed to providing
      quality services to you and this policy outlines our ongoing obligations
      to you in respect of how we manage your Personal Information.
    </p>
    <p>
      By using our services or our website you consent to the collection, use,
      disclosure and transfer of your personal information as set out in this
      Privacy Policy.
    </p>
    <p class="mb-2">
      We have adopted the Australian Privacy Principles (APPs) contained in
      the Privacy Act 1988 (Cth) (the Privacy Act). The APPs govern the way in
      which we collect, use, disclose, store, secure and dispose of your Personal
      Information.
    </p>
    <p class="mb-5">
      A copy of the Australian Privacy Principles may be obtained from the
      website of The Office of the Australian Information Commissioner at
      <a class='regularLink'
      href='https://www.oaic.gov.au/privacy/australian-privacy-principles'
      target='_blank'>oaic.gov.au/privacy/australian-privacy-principles</a>.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      What is Personal Information and why do we collect it?
    </h2>
    <p class="mb-2">
      Personal Information is information or an opinion that identifies an individual.
      Examples of Personal Information we collect include: names, addresses,
      email addresses, phone and facsimile numbers.
    </p>
    <p class="mb-2">
      This Personal Information is obtained in many ways including interviews,
      correspondence, by telephone and facsimile, by email, via our website
      <b>avwa.org.au</b> and <b>avwa.legaltechhelper.com.au</b>,
      from our Microsoft Azure, from media and publications, from other publicly
      available sources, from cookies and from third parties.
      We don’t guarantee website links or policy of authorised third parties.
    </p>
    <p class="mb-2">
      If you use our website, certain information may be collected regarding
      how you use our website. This includes Internet identifiers. For example,
      IP addresses and the geographical location of your ISP data centre.
      We collect your Personal Information for the primary purpose of providing our
      services to you, providing information to our clients and marketing.
      We may also use your Personal Information for secondary purposes closely
      related to the primary purpose, in circumstances where you would reasonably
      expect such use or disclosure. You may unsubscribe from our
      mailing/ marketing lists at any time by contacting us in writing.
    </p>
    <p class="mb-4">
      When we collect Personal Information we will, where appropriate and where
      possible, explain to you why we are collecting the information and how we
      plan to use it.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Sensitive Information
    </h2>
    <p class="mb-2">
      Sensitive information is defined in the Privacy Act to include information
      or opinion about such things as an individual's racial or ethnic origin,
      political opinions, membership of a political association, religious or
      philosophical beliefs, membership of a trade union or other professional
      body, criminal record or health information.
    </p>
    <p class="mb-2">
      Sensitive information will be used by us only:
      <ul class="mb-4">
        <li><span>For the primary purpose for which it was obtained</span></li>
        <li><span>For a secondary purpose that is directly related to the primary purpose</span></li>
        <li><span>With your consent; or where required or authorised by law.</span></li>
      </ul>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Third Parties
    </h2>
    <p class="mb-4">
      Where reasonable and practicable to do so, we will collect your Personal
      Information only from you. However, in some circumstances we may be
      provided with information by third parties. In such a case we will take
      reasonable steps to ensure that you are made aware of the information
      provided to us by the third party.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      How do we use and disclose personal information?
    </h2>
    <p class="mb-2">
      Purposes for which we may collect, use or disclose your personal
      information include:
      <ul>
        <li><span>
          to provide our services. This includes giving you advice, information,
          resources, or referrals to other organisations. This also includes
          incidental uses and disclosures that occur as part of the standard
          operation of our organisation;
        </span></li>
        <li><span>
          to communicate with you. For example, to respond to your enquiries,
          questions, requests and complaints relating to our services;
        </span></li>
        <li><span>
          to assess applications for work and volunteer positions at AVWA; and
        </span></li>
        <li><span>
          complying with applicable laws. We may use or disclose your personal
          information when complying with our legal and regulatory obligations;
        </span></li>
      </ul>
    </p>
    <p class="mb-2">
      If you do not provide us with the required personal information,
      we may not be able to give you access to our services or assistance.
    </p>
    <p class="mb-2">
      We may also use your personal information in an anonymised, aggregated or
      de-identified form. For example:
      <ul class="mb-4">
        <li><span>
          for anonymous case studies. Our staff may compile anonymous case
          studies based on the experience of our clients. These are used to
          help explain our services and highlight the needs of our clients;
        </span></li>
        <li><span>
          to conduct research based on our Pathways to Wellbeing web application.
          Usage analytics are collected about users of the <b>Pathways to Wellbeing</b> web
          application. For example, the number of users, and the areas of
          law or mental health services viewed. This information helps us to
          identify information and services that are in high demand and apply
          for additional funding; and
        </span></li>
        <li><span>
          to comply with out funding agreements. We are required to provide
          some de-identified information to funding agencies. We do not provide
          funding agencies with identifying information, such as your name or address.
        </span></li>
        <li><span>
          to identify trends and changes in the demographics of people accessing
          our services. We may use and disclose de-identified information to
          help us understand and communicate the demographics of people
          requesting information and our services.
        </span></li>
      </ul>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Who do we share your personal information with?
    </h2>
    <p class="mb-2">
      We may share your personal information with employees, volunteers,
      contractors or service providers for the purpose of providing our services.
    </p>
    <p class="mb-2">
      Certain third parties may access your personal information. These include:
      <ul class="mb-4">
        <li><span>
          third parties who enable us to provide our services. For example,
          IT systems administrators, process servers, couriers, electronic
          network administrators and professional advisors (e.g. accountants
          and solicitors); and
        </span></li>
        <li><span>
          government bodies, regulatory agencies, law enforcement or exchange
          bodies or courts. We only do this where we are required to do so by
          applicable law or regulation or at their request.
        </span></li>
      </ul>
    <p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Where do we store your personal information?
    </h2>
    <p class="mb-4">
      We, including our service providers, may hold electronic records of
      your personal information using cloud technology, by other electronic means,
      or in paper form. All of the electronic personal information we hold is
      hosted on servers located in Australia. However, in the course of using
      cookies and web-analytics on our website, some information may be held or
      processed outside Australia, including the USA and the European Union.
      It is not practicable for us to specify in advance the location of every
      service provider or user who we deal with.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Disclosure of Personal Information
    </h2>
    <p class="mb-2">
      Your Personal Information may be disclosed in a number of circumstances including the following:
      <ul class="mb-4">
        <li><span>
          Third parties where you consent to the use or disclosure; and
        </span></li>
        <li><span>
          Where required or authorised by law.
        </span></li>
      </ul>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Security of Personal Information
    </h2>
    <p class="mb-4">
      Your Personal Information is stored in a manner that reasonably protects it
      from misuse and loss and from unauthorised access, modification or disclosure.
      When your Personal Information is no longer needed for the purpose for
      which it was obtained, we will take reasonable steps to destroy or
      permanently de-identify your Personal Information. However, most of the
      Personal Information is or will be stored in client files which will be
      kept by us for a minimum of 7 years.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Access to your Personal Information
    </h2>
    <p class="mb-2">
      You may access the Personal Information we hold about you and to update
      and/ or correct it, subject to certain exceptions. If you wish to access
      your Personal Information, please contact us in writing.
    </p>
    <p class="mb-4">
      AVWA will not charge any fee for your access request, but may charge an
      administrative fee for providing a copy of your Personal Information.
      In order to protect your Personal Information we may require
      identification from you before releasing the requested information.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Maintaining the Quality of your Personal Information
    </h2>
    <p class="mb-4">
      It is important to us that your Personal Information is up to date.
      We will take reasonable steps to make sure that your Personal Information
      is accurate, complete and up-to-date. If you find that the information
      we have is not up to date or is inaccurate, please advise us as soon as
      practicable so we can update our records and ensure we can continue to
      provide quality services to you.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      How do we use cookies?
    </h2>
    <p class="mb-2">
      We may from time to time use cookies or other trackers on our website
      (Trackers). Trackers allow us to access and store information or resources
      on a user’s device when they interact with our website. One type of
      Tracker is a cookie, which is a very small file which a website uses to
      identify you when you come back to the website and to store details about
      your use of the website.
    </p>
    <p class="mb-2">
      Our website uses Google Analytics to analyse web traffic coming to our website.
      If you do not agree to the use of Trackers on our website, please disable
      them by following the instructions for your browser or device, or by using
      the automated disabling tool where available.
    </p>
    <p class="mb-4">
      Our <b>Pathways to Wellbeing</b> web application uses
      Matomo Analytics cookies for research purposes. These analytics will
      capture information, such as the number of users
      and the areas of law and mental health services viewed.
      Our <b>Pathways to Wellbeing</b> web application uses session cookies,
      which is a cookie that only exists in temporary memory while you
      navigate our <b>Pathways to Wellbeing</b> web application.
      Session cookies expire or are deleted when you close the web browser.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Policy Updates
    </h2>
    <p class="mb-4">
      This Policy may change from time to time and is available on our website.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Privacy Policy Complaints and Enquiries
    </h2>
    <p class="mb-4">
    If you have any queries or complaints about our Privacy Policy please contact us at:
      <ul>
        <li><span>
          Address: 30-32 Lennox Street, <span class="inline md:hidden"><br /></span>Richmond, VIC 3121
        </span></li>
        <li><span>
          Email:
          <a class='regularLink' href='mailto&#58;%6&#57;nfo&#64;%61v&#119;%61&#46;&#111;r%67%&#50;E&#97;u'>in&#102;o&#64;&#97;vw&#97;&#46;&#111;r&#103;&#46;au</a>
        </span></li>
        <li><span>
          Phone:
          <a class='regularLink' href='tel:0394289078'>(03) 9428 9078</a>.
        </span></li>
      </ul>
    </p>
    <p class="mt-2" style="font-style: italic">
      The Privacy Policy was last updated on the 12<sup>th</sup> February 2024.
    </p>
    </div>`,

  // About Page
  "about-heading": "About Pathways to Wellbeing",
  "about-text": `
    <div class="static-conent font-serif">
    <p class="mb-2">
    AVWA’s new <b>Pathways to Wellbeing</b> web application helps Vietnamese Victorians
    learn more about the new Victorian Mental Health system.
    </p>
    <p class="mb-2">
    You can use <b>Pathways to Wellbeing</b> to understand your rights and also to
    find mental health services, legal services and interpreting services in
    Victoria for yourself or someone you know.
    </p>
    <p class="mb-2">
      <b>Pathways to Wellbeing</b> is not legal advice and is not a
      substitute for legal advice.
    </p>
    <p class="mb-4">
    <b>Pathways to Wellbeing</b> does not give medical advice.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Version
    </h2>
    <p class="mb-4">
      <b>Pathways to Wellbeing</b> - v1.0.0
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Credits
    </h2>
    <p class="mb-2">
      Designed and built by Legal Tech Helper.
    </p>
    <p class="mb-2">
      Content by CQ University, Legal Tech Helper, Mental Health Legal Centre
      and Australian Vietnamese Women’s Association.
    </p>
    <p class="mb-2">
      Legal review by Mental Health Legal Centre.
    </p>
    <p class="mb-2">
      UX Design and Review by Legal Tech Helper,
      CQ University and Australian Vietnamese Women’s Association.
    </p>
    <p class="mb-2">
      Illustrations by Tammy Winter Design.
    </p>
    <p class="mb-2">
    Thank you to our project team for their collaboration:
    </p>
    <p class="my-1" style="margin-top: 0.25rem;">
      Australian Vietnamese Women’s Association: Solomon Abbay, Huy Luu, Tuan Bui, The Ly;
    </p>
    <p class="my-1" style="margin-top: 0.25rem;">
      CQ University: Dr. Jacob Deem, Assoc Prof Huong Le, Dr. Luke Price, Dr. Victoria Lambropolous;
    </p>
    <p class="my-1" style="margin-top: 0.25rem;">
      Legal Tech Helper: Tatiana Lenz, Samantha Lovrich;
    </p>
    <p class="mt-1 mb-4" style="margin-top: 0.25rem;">
      Mental Health Legal Centre: Kate Windmill.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Thank you
    </h2>
    <p class="mb-2">
      Australian Vietnamese Women's Association's <b>Pathways to Wellbeing</b> is
      funded by a grant under the Victorian Government's Diverse Communities
      Mental Health and Wellbeing Grants Program.
      All the participants of the project wish to express their thanks to the
      Victorian Government for their support.
    </p>
    </div>`,

  // Website Terms Page
  "terms-heading": "Australian Vietnamese Women’s Association Website Terms",
  "terms-text": `
    <div class="static-conent font-serif">
    <p style="font-style: italic">
      These Website Terms apply to all individuals using the AVWA website
      (including the <b>Pathways to Wellbeing</b> web application). These terms explain:
      <ul style="font-style: italic">
        <li><span>
          who we are;
        </span></li>
        <li><span>
          how the terms apply;
        </span></li>
        <li><span>
          the services provided by our Website;
        </span></li>
        <li><span>
          the limits of our Website;
        </span></li>
        <li><span>
          requirements and restrictions on using our Website;
        </span></li>
        <li><span>
          our ownership of the Website and its contents;
        </span></li>
        <li><span>
          how we handle your personal information;
        </span></li>
        <li><span>
          our responsibilities;
        </span></li>
        <li><span>
          how you can contact us; and
        </span></li>
        <li><span>
          other general matters.
        </span></li>
      </ul>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      1. Who are we?
    </h2>
    <p class="mb-4">
      We are the Australian Vietnamese Women’s Association (AVWA). We are a community
      organisation that offers help to Vietnamese Victorians.
      As part of our services, we operate our website <b>avwa.org.au</b> (Website).
      This Website is intended to provide general information and information
      about our community services and programs.
      In these website terms and conditions (Website Terms), a reference to
      "we", "us" or "our" refers to the AVWA.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      2. What are these terms?
    </h2>
    <p class="mb-2">
      These Website Terms explain the terms and conditions that apply to use
      of our Website and any services provided through the Website, such as the
      <b>Pathways to Wellbeing</b> web application at <b>avwa.legaltechhelper.com.au</b>.
      Separate terms and conditions apply to our community services and programs.
    </p>
    <p class="mb-4">
      These Website Terms contain the entire agreement between the parties
      relating to the use of our Website. Any previous agreement or
      understanding relating to the use of our Website is replaced by these
      Website Terms.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      3. Who do these terms apply to?
    </h2>
    <p class="mb-2">
      When you use our Website, these Website Terms form an agreement between
      us and you. These Website Terms apply to anyone using the Website or
      any services provided through the Website.
    </p>
    <p class="mb-4">
      These terms do not apply to our community services and programs.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      4. What services does this website provide?
    </h2>
    <p class="mb-2">
      The Website provides:
      <ol>
        <li>
          (a) information about our community services and programs and;
        </li>
        <li>
        (b) a web application which allows you to seek mental health legal
        information that may be relevant to you (<b>Pathways to Wellbeing</b>
        web application). The <b>Pathways to Wellbeing</b> web application
        includes general legal and mental health information intended to
        assist Vietnamese Victorians.
        </li>
      </ol>
    </p>
    <p class="mb-2">
      The Website content and functionality can change from time to time.
    </p>
    <p class="mb-4">
      The Website contains links to other websites operated by third parties (Links).
      These Links are provided for convenience only and may be subject to
      updates, revisions or other changes by the third parties that control
      or own those sites. We are not responsible for the operation of or content
      on any third party websites accessed through Links.
      You access Links at your own risk.
    <p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      5. What are the limitations of this website?
    </h2>
    <p class="mb-2">
    The information on this Website is for general information only.
    This Website, including via the <b>Pathways to Wellbeing</b> web application,
    does not provide legal advice or medical or health advice.
    </p>
    <p class="mb-2">
      Legal information on this Website is intended to explain the law and
      the legal system in general ways. Some parts of this Website may allow
      you to seek legal information by inputting your details. However, the
      legal information is not legal advice and is not tailored to your
      specific case. If you require legal advice, you can contact a lawyer.
    <p>
    <p class="mb-2">
      To the extent permitted by law:
      <ol>
        <li>
          (a) We disclaim all representations and warranties, express,
          implied or statutory, not expressly set out in these Website Terms.
          This includes any implied warranties of merchantability, fitness
          for a particular purpose and non-infringement.
        </li>
        <li>
        (b) We do not guarantee the Website will be available or that your use
        of the Website will be uninterrupted or error-free. The Website
        is provided to you on an “as is” and “as available” basis.
        </li>
      </ol>
    </p>
    <p class="mb-4">
      For clarity, nothing in these Website Terms limits or excludes any guarantees,
      warranties, representations or conditions implied or imposed by
      law (including the Australian Consumer Law) that cannot be limited or excluded.
    <p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      6. How do you use this website?
    </h2>
    <p class="mb-4">
      The Website relies on internet access and may not work with all
      internet browsers. We cannot guarantee that the Website will work under
      all conditions or on all devices, including tablets and mobile devices.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      7. What restrictions apply to this website?
    </h2>
    <p class="mb-4">
      You cannot, and must not allow any other person to:
      <ol>
        <li>
          (a) modify, disassemble, decompile, reverse engineer or otherwise
          attempt to gain access to the source code of any portion of the Website;
        </li>
        <li>
          (b) transmit any content which could harm, disable, destroy or
          interfere with the Website. For example, any viruses, worms, spyware,
          or adware;
        </li>
        <li>
          (c) use the Website in a manner that interferes with the standard
          operation of the Website or the use of the Website by other persons; or
        </li>
        <li>
          (d) use any robot, spider, scraper, or other automated means to
          access the Website.
        </li>
      </ol>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      8. Who owns this website?
    </h2>
    <p class="mb-2">
      This Website, including all content on this Website, is owned or licensed
      by us. Except as expressly stated in these Website Terms, your use of this
      Website does not provide you with any rights to the Website or any content
      on this Website.
    </p>
    <p class="mb-4">
      We grant you a right to use this Website, solely for your personal use or
      to refer clients to us. You may use this Website to obtain information
      and to request assistance from us. You grant us a royalty-free, worldwide,
      irrevocable, perpetual license to use and incorporate into the Website any
      general suggestions, enhancement requests, recommendations or other
      feedback you provide us.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      9. How do we handle your personal information?
    </h2>
    <p class="mb-4">
      We attempt to limit the collection of personal information via our Website.
      When you use the <b>Pathways to Wellbeing</b> web application,
      certain de-identified information may be collected based on your use of
      the <b>Pathways to Wellbeing</b> application. This is used to help us
      understand the demographics of people requesting legal information and
      the issues that are important to our users. If we collect any personal
      information from you while you are using our Website, we will handle it
      in accordance with our Privacy Policy.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      10. What is our responsibility for this website?
    </h2>
    <p class="mb-4">
      To the extent permitted by law, the AVWA, our staff, contractors and
      service providers:
      <ol>
        <li>
          (a) are not liable or responsible for the actions or inactions of
          you or any other individual using our Website;
        </li>
        <li>
          (b) are not liable for the accuracy, suitability or currency of
          any information accessed or received by you through our Website; and
        </li>
        <li>
          (c) are not liable for your use of, or reliance on, our Website or
          any information on our Website. Nothing in these Website Terms limits
          or excludes any liability imposed by law (including the Australian
          Consumer Law) that  cannot limited or excluded.
        </li>
      </ol>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      11. Other matters
    </h2>
    <h2 class="mt-4 font-medium text-brandTextDark">
      11.1 What laws apply to these Website Terms?
    </h2>
    <p class="mb-4">
      These Website Terms are governed by the laws of Victoria, Australia.
      If a dispute arises in relation to these Website Terms, you agree that
      the dispute can be brought before the courts in Victoria, Australia.
    </p>
    <h2 class="mt-2 font-medium text-brandTextDark">
      11.2 How can we change these Website Terms?
    </h2>
    <p class="mb-4">
      We can change these Website Terms at any time by updating them on our
      Website. The updated Website Terms will apply from the date they are
      updated on our Website.
    </p>
    <h2 class="mt-2 font-medium text-brandTextDark">
      11.3 How can you contact us?
    </h2>
    <p class="mb-4">
      You can contact us:
      <ul>
        <li><span>
          By email:
          <a class='regularLink' href='mailto&#58;%6&#57;nfo&#64;%61v&#119;%61&#46;&#111;r%67%&#50;E&#97;u'>in&#102;o&#64;&#97;vw&#97;&#46;&#111;r&#103;&#46;au</a> <span class="hidden md:inline"><b>or</b></span>
        </span></li>
        <li><span>
          Through our website:
          <a class='regularLink' href='https://www.avwa.org.au/en/contact-us' target='_blank'>avwa.org.au/en/contact-us</a> <span class="hidden md:inline"><b>or</b></span>
        </span></li>
        <li><span>
          By phone:
          <a class='regularLink' href='tel:0394289078'>(03) 9428 9078</a>.
        </span></li>
      </ul>
    </p>
    <p class="mt-2" style="font-style: italic">
    The Website Terms were last updated on the the 12<sup>th</sup> of February 2024.
    </p>
    </div>`,
}

export default translationsEn
