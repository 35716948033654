import React, {useContext} from "react"
import InterviewContext from "./InterviewContext"
import IconInformationCircleOutline from "./icons/IconInformationCircleOutline"

const HelpButton = (props) => {
  const { dispatch } = useContext(InterviewContext);
  const showModalHelp = (e) => {
    e.preventDefault()
    dispatch({ type: 'UPDATE_MODAL_WIN_STATE', modalWindowState: "modalHelp" })
  }

  return (
    <button
    key={props.buttonKey}
    onClick={(e) => {showModalHelp(e)}}
    className={"mt-3 px-6 py-4 text-white text-md  rounded-md shadow-md font-medium " +
    (props.isLoadedInApp === null ? 'bg-brandPurple hover:bg-brandPurpleDark' : 'bg-gray-500')}
    name="helpButton"
    value="help">
      <IconInformationCircleOutline className="inline align-bottom text-white mr-1" width='1.5rem' height='1.5rem' />
      {props.help.label}
    </button>
  )
}

export default HelpButton;
