import React, {useState, useEffect, useLayoutEffect} from "react"
import InterviewMainText from "../../InterviewMainText"
import ApplicationButton from "../ApplicationButton"

import './SplashPage.css'

const SplashPage = (props) => {

  const [scriptElement, setScriptElement] = useState(null)

  useEffect(() => {
    if (typeof(props.interview.script) !== "undefined") {
      const newElement = document.createElement('script')
      setScriptElement(newElement)
      const inlineCode = document.createTextNode(props.interview.script)
      newElement.appendChild(inlineCode)
      document.body.appendChild(newElement)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  useLayoutEffect(() => {
    if (scriptElement !== null) {
      document.body.removeChild(scriptElement)
      setScriptElement(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  return (
    <>
    <div id="splash-wrapper" >

      {/*<!-- Background -->*/}
      <div  id ="splash-background"></div>

      {/*<!-- Main -->*/}
      <div  id="splashPage" className="flex flex-col items-center mx-auto max-w-7xl md-px-2 sm:px-6 lg:px-8 xl:lg:px-0">

        {/* <!--Left Col--> */}
        <div id="first-panel" className="flex flex-col w-full lg:w-2/5 justify-center lg:items-start overflow-hidden">
          <div className="heroSection w-full md:aspect-[21/9] lg:aspect-[40/21] lg:w-3/5"></div>
        </div>

        {/* <!--Right Col--> */}
        <div id="second-panel" className="w-full lg:w-3/5">
          <h1 className="my-2 pt-4 md:text-left px-4 text-2xl md:text-3xl text-black opacity-90 font-semibold leading-tight">
            <span className="bg-clip-text text-brandPurple">
              { props.interview.questionText }
            </span>
          </h1>
          <div className="px-4 text-brandTextDark text-base lg:text-lg">

            <InterviewMainText
              isLoadedInApp={props.isLoadedInApp}
              inteviewTitle=""
              interviewText={props.interview.subquestionText}
              interviewTerms={props.interview.terms}
            />
          </div>

          {/* Application buttons */}
          <div className="px-4 pt-2 pb-8 text-md">
            { props.interview.fields &&
              props.interview.fields.length > 0 &&
              props.interview.fields[0].choices &&
              props.interview.fields[0].choices.length > 0 &&
              props.interview.fields[0].choices.map((interviewObj, index) => (
              <ApplicationButton key={index}
                index = {index}
                id = {"app-button-" + index}
                name = {props.interview.fields[0].variable_name || interviewObj.variable_name}
                label =  {interviewObj.label}
                value = {interviewObj.value}
                buttonColor = {interviewObj.color}
                handleClick={props.handleClick}
                isLoadedInApp = {props.isLoadedInApp}
                showButtonSpinner = {props.showButtonSpinner}
              />
            ))}

          </div>

        </div>
      </div>
    </div>
    </>
  )
}

export default SplashPage;
