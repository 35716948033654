import React from "react";
// import { useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { getCookie } from "./Utilities"

import IconBack from "./icons/IconBack"

const BackLink = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  return (
    <a id="back-button" className="text-brandBlue text-lg" href="#/" onClick={(e) => props.goPrevQuestion(e)}>
      <IconBack className="inline stroke-width-2-25 text-brandBlue h-5 w-5 mr-2" />
      {translate("prev-question-txt")}
    </a>
  )
}

export default BackLink;
