import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
// import DOMPurify from 'dompurify'
import { XMarkIcon } from '@heroicons/react/20/solid'
// import IconInformationCircleOutline from "./icons/IconInformationCircleOutline"

import "./ModalWindow.css"
import "./pages/Interview.css"

const ModalGenericDialog = (props) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={props.modalSwitch} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {props.closeModalHelp()}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* Default needs to be made wider, see https://github.com/tailwindlabs/headlessui/discussions/412
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              */}
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:min-w-[90vw] md:min-w-[80vw] lg:min-w-[60vw]">

                <div className="bg-white px-4 pt-5 pb-4 sm:pb-2">
                  <div className="closeModal">
                    <XMarkIcon className="text-slate-900" onClick={(e) => props.closeModalHelp()} />
                  </div>

                  <div>
                    <div className="sm:mx-4">
                      <div className="flex space-between my-4">
                        <Dialog.Title as="h1" className="pt-3 pb-2 leading-6 modal-header">
                          { <span dangerouslySetInnerHTML={{ __html: props.header}} /> }
                        </Dialog.Title>
                      </div>
                      <div id="generic-dialog">
                        <p className="text-md text-brandTextDark helpText">
                          { <span dangerouslySetInnerHTML={{ __html: props.mainText}} /> }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-4 md:pb-6 px-4 sm:flex sm:flex-row-reverse sm:px-6 modal-button">
                  <button
                    type="button"
                    className="focus:outline-none w-full bg-brandBlue hover:bg-brandBlueDark text-white text-md px-4 py-4 md:py-3 mb-1 mr-4 sm:mt-4 rounded-md shadow-md sm:w-auto lg:font-medium lg:py-4 lg:px-8 sm:mb-0 lg:text-md"
                    onClick={() => props.closeModalHelp()}
                  >
                    {props.closeModalHelpButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalGenericDialog;
