import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {QuestionMarkCircleIcon} from '@heroicons/react/24/outline'

const ModalConfirmWindow = (props) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={props.confirmSwitch} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {props.closeConfirmWindow()}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/*
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="closeModal">
                    <XMarkIcon className="text-slate-900" onClick={(e) => props.closeConfirmWindow()} />
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-brandBlue sm:mx-0 sm:h-10 sm:w-10">
                      <QuestionMarkCircleIcon className="inline text-white" width='1.5rem' height='1.5rem' />
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                      <Dialog.Title as="h3" className="pt-1 pb-2 text-2xl leading-6 text-slate-900">
                        {props.confirmHeader}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-md text-brandTextDark">
                          {props.confirmText}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-5 px-4 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="focus:outline-none w-full bg-brandBlue hover:bg-brandBlueDark text-white text-md secondary-wide px-4 py-4 md:py-3 mb-5 rounded-md shadow-md sm:w-auto lg:font-medium lg:py-4 lg:px-8 sm:mb-0 lg:text-md"
                    onClick={(e) => {
                      e.preventDefault()
                      props.closeConfirmWindow()
                    }}
                  >
                    {props.no}
                  </button>
                  <button
                    type="button"
                    className="focus:outline-none w-full bg-brandBlue hover:bg-brandBlueDark text-white text-md px-4 py-4 md:py-3 mb-1 mr-5 rounded-md shadow-md sm:w-auto lg:font-medium lg:py-4 lg:px-8 sm:mb-0 lg:text-md"
                    onClick={(e) => {
                      e.preventDefault()
                      props.closeConfirmWindow()
                      props.restartInterview(e)
                    }}
                  >
                    {props.yes}
                  </button>
                </div>
              </Dialog.Panel>
              */}

              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:pb-2">
                  <div className="closeModal">
                    <XMarkIcon className="text-slate-900" onClick={(e) => props.closeConfirmWindow()} />
                  </div>
                  <div>
                    <div className="mt-3 sm:mt-0 sm:ml-4">
                      <div className="flex space-between my-4">
                        <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-brandBlue ml-0 mr-3 sm:h-10 sm:w-10"
                        style={{margin:"auto 1rem auto 0"}}>
                          <QuestionMarkCircleIcon className="inline text-white" width='1.5rem' height='1.5rem' />
                        </div>
                        <Dialog.Title as="h4" className="pt-2 pb-2 text-2xl leading-6 text-brandPurpleDark">
                          {props.confirmHeader}
                        </Dialog.Title>
                      </div>
                      <div className="mt-2">
                        <p className="text-md text-brandTextDark">
                          {props.confirmText}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-4 md:pb-6 px-4 sm:flex sm:flex-row-reverse sm:px-6 modal-button">
                  <button
                    type="button"
                    className="focus:outline-none w-full bg-brandBlue hover:bg-brandBlueDark text-white text-md secondary-wide px-4 py-4 md:py-3 mb-5 rounded-md shadow-md sm:w-auto lg:font-medium lg:py-4 lg:px-8 sm:mb-0 lg:text-md"
                    onClick={(e) => {
                      e.preventDefault()
                      props.closeConfirmWindow()
                    }}
                  >
                    {props.no}
                  </button>
                  <button
                    type="button"
                    className="focus:outline-none w-full bg-brandBlue hover:bg-brandBlueDark text-white text-md px-4 py-4 md:py-3 mb-1 mr-5 rounded-md shadow-md sm:w-auto lg:font-medium lg:py-4 lg:px-8 sm:mb-0 lg:text-md"
                    onClick={(e) => {
                      e.preventDefault()
                      props.closeConfirmWindow()
                      props.restartInterview(e)
                    }}
                  >
                    {props.yes}
                  </button>
                </div>
              </Dialog.Panel>



            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ModalConfirmWindow;
