const getLanguage = (url) => {
  const supportedLanguages = ["en", "vi"]
  let derivedLang = ""
  supportedLanguages.forEach((lang) => {
  	derivedLang = derivedLang + (url.endsWith("/" + lang + "/") ? lang: "")
  	derivedLang = derivedLang + (url.endsWith("/" + lang) ? lang: "")
  })
  if (derivedLang === "") derivedLang = "en"
  return derivedLang
}

const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export { getLanguage, classNames }

const exitQuickly = () => {
  // console.log("Exit quickly to ", process.env.REACT_APP_ESCAPE_URL)
  // document.title = process.env.REACT_APP_ESCAPE_TITLE
  // document.querySelector("link[rel~='icon']").href = process.env.REACT_APP_ESCAPE_ICON_URL
  window.open(process.env.REACT_APP_ESCAPE_URL, '_blank').focus()
  window.location.replace('http://google.com')
  try {
   window.open('','_self').close()
 } catch(e) { }
}

export { exitQuickly }

const getCookie = (cookieName) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${cookieName}=`)
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }
}

export { getCookie }
