import {React, useEffect, Suspense} from "react"
import debounce from "lodash/debounce"
import ModalWindows from "./ModalWindows"
import Spinner from "./Spinner"

import "./SimpleTextPage.css"

const SimpleTextPage = (props) => {

  const fixOveflowElement = (elemenID) => {
    const wndowWidth = document.documentElement.scrollWidth
    const targetWindowHeight = document.documentElement.scrollHeight - 76
    const interviewElement = document.getElementById(elemenID)
    if (wndowWidth < 768) {
      interviewElement.style.height = targetWindowHeight + "px"
    }
  }

  useEffect(()=>{
    fixOveflowElement("interview")
  }, [])

  const handleResize = () => {
    window.location.reload()
  }

  window.onresize = (e) => {
    debounce(handleResize(), 500)
  }

  return (
    <Suspense fallback={<Spinner />}>
      <>
        <ModalWindows
          interview={null}
          restartInterview={null}
        />

        <div id="interview" className="flex simple-page">
          <div className="m-auto">
            <div id="interview-outer-container"
              className="main-text-frame max-w-7xl px-4 py-0 my-8 rounded-md md:bg-white md:py-6 md:px-6 lg:p-10 md:shadow-lg md:w-screen">
              <h1 className="text-lg lg:text-2xl font-bold text-brandBlue">{props.heading}</h1>
              <div
                 dangerouslySetInnerHTML={{__html: props.mainText}}
               />
            </div>
          </div>
        </div>
      </>
    </Suspense>
    )
}

export default SimpleTextPage;
