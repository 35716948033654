import React from "react"
// import { useLocation } from "react-router-dom"
// import { useTranslation } from 'react-i18next'
// import { getCookie, classNames } from "../../Utilities"

import MenuItem from "../special-screens/MenuItem"
import '../special-screens/CardPage.css'
import '../special-screens/MainMenuPage.css'

const SpecialMainMenuPage = (props) => {
  // const lang = getCookie("DA-language")
  // // eslint-disable-next-line
  // const { t, i18n } = useTranslation()
  // const translate = i18n.getFixedT(lang)

  return (
    <>
      {/* Interview Header */}
      <h1 className="interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
        { props.interview.questionText }
      </h1>

      <div className="interview-body font-serif mt-2 text-brandTextDark lg:mt-4">
        {props.interview.fields[0].choices[0].help}
      </div>

      {/* "Main Menu Items */}
      <div className="flex flex-wrap -mx-4">

        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index1) => (
            interviewObj.color === "primary" &&
            <MenuItem key={index1}
              index={index1}
              name={props.interview.fields[0].variable_name || interviewObj.variable_name}
              label={interviewObj.label}
              text={interviewObj.help}
              value={interviewObj.value}
              buttonColor={interviewObj.color}
              isLoadedInApp={props.isLoadedInApp}
              showButtonSpinner={props.showButtonSpinner}
              handleClick={props.handleClick}
              menuType="menu-by-two"
            />
        ))}
      </div>

      <h1 className="pt-4 interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
        {props.interview.fields[0].choices[4].help}
      </h1>

      {/* "Quick Links */}
      <div className="flex flex-wrap -mx-4">
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index2) => (
            interviewObj.color === "warning" &&
            <MenuItem key={index2}
              index={index2}
              name={props.interview.fields[0].variable_name || interviewObj.variable_name}
              label={interviewObj.label}
              text={interviewObj.help}
              value={interviewObj.value}
              buttonColor={interviewObj.color}
              isLoadedInApp={props.isLoadedInApp}
              showButtonSpinner={props.showButtonSpinner}
              handleClick={props.handleClick}
              menuType="menu-by-two"
            />
        ))}
      </div>

    </>
  )
}

export default SpecialMainMenuPage;
