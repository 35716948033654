import React, {useState, useEffect, useRef, useContext} from "react"
import { useTranslation } from 'react-i18next'
// import {useLocation} from "react-router-dom"
// import debounce from "lodash/debounce"
import BlankCanvas from "./BlankCanvas"
import Spinner from "./Spinner"
import InterviewWrapper from "./InterviewWrapper"
import {getCookie, exitQuickly} from "./Utilities"
import InterviewContext from "./InterviewContext"
import LastPage from "./pages/LastPage"
import ModalWindows from "./ModalWindows"

import './Pathway.css'

const Pathway = () => {

  let lang = getCookie("DA-language")
  // Default language is English
  if ( typeof(lang) === "undefined" || lang !== "vi" ) {
    lang = "en"
    document.cookie = "DA-language=en; SameSite=Strict; Secure"
  }

  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  let translate = i18n.getFixedT(lang)
  document.title = translate("web-app-title")

  // const lang = getCookie("DA-language")
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoadedInApp, setIsLoadedInApp] = useState(null)
  const [needsToBeBlank, setNeedsToBeBlank] = useState(null)
  const [showButtonSpinner, setShowButtonSpinner] = useState(false)
  const [sessionExpired, setSessionExpired] = useState(false)
  const [historyLength, setHistoryLength] = useState(0)
  // const [needResizeToggler, setNeedResizeToggler] = useState(false)
  const [error, setError] = useState(null)
  const goingBack = useRef("goBack")

  const {state, dispatch} = useContext(InterviewContext)

  const pseudoHistoryArray = useRef([])

  useEffect(() => {
    if (state.nextState !== null && typeof(state.nextState) != "undefined") {
      if (state.nextState === "quickExit") {
        setNeedsToBeBlank(true)
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
        exitQuickly()
      }

      // Only go back if allowed
      if (state.nextState === "goBack" && state.data.allow_going_back) {
        // If sitting on resultset screen, go back to search screen
        if ( state.data.event_list[0] === "ls_resultset_page" ||  state.data.event_list[0] === "mental_health_resultset_page") {
          if ( state.data.event_list[0] === "mental_health_resultset_page" ) {
            goPrevQuestion("", "mental_health_search")
          }
          if ( state.data.event_list[0] === "ls_resultset_page" ) {
            goPrevQuestion("", "legal_services_search")
          }
        // Otherwise, go back to previous screen
        } else {
          goPrevQuestion(null)
        }
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }

      if (state.nextState === "setLanguageVi") {
        setPageLanguage("vi")
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }
      if (state.nextState === "setLanguageEn") {
        setPageLanguage("en")
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }
      if (state.nextState === "setBackToMainMenu") {
        goBackToMainMenu()
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }
      if (state.nextState === "qlMentalHealth") {
        goToMentalHealth()
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }
      if (state.nextState === "qlLegal") {
        goToLegal()
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }
      if (state.nextState === "qlInterpreting") {
        goToInterpreter()
        dispatch({
          type: 'UPDATE_NEXT_STATE',
          nextState: null
        })
      }
    }
  }, [state.nextState, state.data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // set initial history length
    if (historyLength === 0) {
      // eslint-disable-next-line no-restricted-globals
      setHistoryLength(history.length)
    }

    if (pseudoHistoryArray.current.length === 0){
      pseudoHistoryArray.current = ([...pseudoHistoryArray.current, window.location.href + ""])
    }

    const sessIDStoredInCookie = getCookie("DA-sessionID")
    let languageStoredInCookie = getCookie("DA-language")
    // Default language is English
    if ( typeof(languageStoredInCookie) === "undefined" || languageStoredInCookie !== "vi" ) {
      languageStoredInCookie = "en"
      document.cookie = "DA-language=en; SameSite=Strict; Secure"
    }

    fetchData(sessIDStoredInCookie, languageStoredInCookie)

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  window.onload = (e) => {
      // set initial history length
      if (historyLength === 0) {
        // eslint-disable-next-line no-restricted-globals
        setHistoryLength(history.length)
      }

      if (pseudoHistoryArray.current.length === 0){
        pseudoHistoryArray.current = ([...pseudoHistoryArray.current, window.location.href + ""])
      }
  }

  // window.onresize = (e) => {
  //   console.log("Resize detected!")
    // debounce(() => {
    // setNeedResizeToggler(!needResizeToggler)
    // console.log("Resizing ...", needResizeToggler)
    // }, 500)
  // }

  window.onpopstate = (e) => setTimeout(
    async (e) => {
      setIsLoaded(false)
      if (goingBack.current === "goBack") {
        // stop doing anything until we have done the fetch of the previous question
        goingBack.current = "stopForNow"
        if (!state.data.allow_going_back) {
          goingBack.current = "noNeed"
          // eslint-disable-next-line no-restricted-globals
          const pagesToGoBack = (history.length - historyLength) * -1
          if (pagesToGoBack < 0) {
            // eslint-disable-next-line no-restricted-globals
            history.go(-2)
          }
        }
        let prevQuestion = await fetchPrevQuestion()
        goingBack.current = "goBack"
        if (typeof(prevQuestion) === "undefined") {
          goingBack.current = "noNeed"
          // eslint-disable-next-line no-restricted-globals
          history.go(-2)
        } else {
          // eslint-disable-next-line no-restricted-globals
          history.pushState({ page: prevQuestion.steps}, "", "/page-" + prevQuestion.steps)
          setIsLoaded(true)
          return true
        }
      }
      setIsLoaded(true)
    },
  0)

  const setShowButtonSpinnerWrapper = async () => {
    setTimeout(() => {
      setShowButtonSpinner(true)
    }, "1000")
  }

  const fetchData = async (sessionID, language) => {
    if (sessionID !== null && typeof(sessionID) !== "undefined") {
      fetchAPIData(sessionID, language)
    } else {
      fetchSessAndData(language)
    }
  }

  const newSessionID = async () => {
    try {
      const urlForNewSession = `${process.env.REACT_APP_DA_SERVER}/api/session/new?key=${process.env.REACT_APP_DA_API_KEY}&i=${process.env.REACT_APP_DA_INTERVIEW}`
      const response = await fetch(
        urlForNewSession,
        {
          cache: "no-cache"
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const json = await response.json()
        document.cookie = "DA-sessionID=" + json.session + "; SameSite=Strict; Secure"
        document.cookie = "DA-language=" + json.lang + "; SameSite=Strict; Secure"
      return json.session
    } catch (error) {
      setError(error)
    }
  }

  const fetchAPIData = async (sessionID, language) => {
    try {
      const urlToGetInterview = `${process.env.REACT_APP_DA_SERVER}` +
        `/api/session/question?key=${process.env.REACT_APP_DA_API_KEY}` +
        `&i=${process.env.REACT_APP_DA_INTERVIEW}` +
        `&session=${sessionID}`

      const response = await fetch(
        urlToGetInterview,
        {
          cache: "no-cache"
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error status: ${response.status}`)
      }

      const json = await response.json()

      if (response.ok && response.status === 200) {

        // Check language
        let currentLanguage = language
        // Default language is English
        if ( typeof(currentLanguage) === "undefined" || currentLanguage !== "vi" ) {
          currentLanguage = "en"
        }
        if (json.lang !== currentLanguage) {
          await resetLanguageWrapper(sessionID, currentLanguage)
        } else {
          dispatch({
            type: 'UPDATE_INTERVIEW_DATA',
            data: json
          })
          // document.title = `${json.browser_title}`
          document.title = translate("web-app-title")
        }

        setIsLoaded(true)
        // eslint-disable-next-line no-restricted-globals
        if (typeof(json.steps) !== "undefined") {
          if (pseudoHistoryArray.current.length < 2) {
            pseudoHistoryArray.current = ([...pseudoHistoryArray.current, window.location.origin + "/page-" + json.steps])
            // eslint-disable-next-line no-restricted-globals
            history.pushState({page: json.steps}, "", "/page-" + json.steps)
          } else {
            // eslint-disable-next-line no-restricted-globals
            history.replaceState({page: json.steps}, "", "/page-" + json.steps)
            window.scrollTo(0, 0)
          }
        }
      }

      if (!response.ok && response.status === 400) {
        document.cookie = "DA-sessionID=null; SameSite=Strict; Secure"
        // document.cookie = "DA-language=null"
        await fetchSessAndData(language)
      }

    } catch (error) {
      setError(error)
      setIsLoaded(true)
    }
  }

  const resetLanguage = async (sessionID, language) => {

    let stringifiedData = JSON.stringify({
      key:`${process.env.REACT_APP_DA_API_KEY}`,
      i: `${process.env.REACT_APP_DA_INTERVIEW}`,
      session: `${sessionID}`,
      action: "set_lang",
      arguments: {
        "lang": language,
      }
    })

    const urlResetLanguage = `${process.env.REACT_APP_DA_SERVER}` +
    "/api/session/action"

    try {
      const response = await fetch(
        urlResetLanguage,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: stringifiedData,
        })

      if (!response.ok) {
        throw new Error(response.status)
      }

      } catch (error) {
        setError(error)
        setIsLoaded(true)
      }
  }

  // const clearInterview = async (sessionID) => {
  //   const stringifiedData = JSON.stringify({
  //     key:`${process.env.REACT_APP_DA_API_KEY}`,
  //     i: `${process.env.REACT_APP_DA_INTERVIEW}`,
  //     session: `${sessionID}`
  //   })
  //
  //   const urlForDAAction = `${process.env.REACT_APP_DA_SERVER}` +
  //   "/api/clear_cache"
  //
  //   try {
  //     const response = await fetch(
  //       urlForDAAction,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         method: "POST",
  //         body: stringifiedData,
  //       })
  //
  //     if (!response.ok) {
  //       throw new Error(response.status)
  //     }
  //
  //     } catch (error) {
  //       setError(error)
  //       setIsLoaded(true)
  //     }
  // }

  const postDAAction = async (sessionID, DAaction, params) => {
    let stringifiedData
    if (typeof(params) === "undefined" || params === "") {
      stringifiedData = JSON.stringify({
        key:`${process.env.REACT_APP_DA_API_KEY}`,
        i: `${process.env.REACT_APP_DA_INTERVIEW}`,
        session: `${sessionID}`,
        action: DAaction
      })
    } else {
      stringifiedData = JSON.stringify({
        key:`${process.env.REACT_APP_DA_API_KEY}`,
        i: `${process.env.REACT_APP_DA_INTERVIEW}`,
        session: `${sessionID}`,
        action: DAaction,
        arguments: params
      })
    }

    const urlForDAAction = `${process.env.REACT_APP_DA_SERVER}` +
    "/api/session/action"

    try {
      const response = await fetch(
        urlForDAAction,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: stringifiedData,
        })

      if (!response.ok) {
        throw new Error(response.status)
      }

      } catch (error) {
        setError(error)
        setIsLoaded(true)
      }
  }

  const setPageLanguage = async (language) => {
    const sessIDStoredInCookie = getCookie("DA-sessionID")
    let languageToSet = language
    // Default language is English
    if ( typeof(languageToSet) === "undefined" || languageToSet !== "vi" ) {
      languageToSet = "en"
    }
    resetLanguageWrapper(sessIDStoredInCookie, languageToSet)
  }

  // *******************************
  // * Top menu navigation buttons *
  // *******************************

  // Switch between languages
  const resetLanguageWrapper  = async (sessionID, language) => {
    setIsLoaded(false)
    await resetLanguage(sessionID, language)
    await simplifiedFetchData(sessionID, language)
    setIsLoaded(true)
  }

  // Navigate back to main menu
  const goBackToMainMenu = async (sessionID) => {
    setIsLoaded(false)
    const sessIDStoredInCookie = getCookie("DA-sessionID")
    const languageStoredInCookie = getCookie("DA-language")
    await postDAAction(sessIDStoredInCookie, "main_menu", "")
    await fetchAPIData(sessIDStoredInCookie, languageStoredInCookie)
    setIsLoaded(true)
  }

  // Quickly find mental health services
  const goToMentalHealth = async (sessionID) => {
    setIsLoaded(false)
    const sessIDStoredInCookie = getCookie("DA-sessionID")
    const languageStoredInCookie = getCookie("DA-language")
    await postDAAction(sessIDStoredInCookie, "dq_mental_health", "")
    await fetchAPIData(sessIDStoredInCookie, languageStoredInCookie)
    setIsLoaded(true)
  }

  // Quickly find legal services
  const goToLegal = async (sessionID) => {
    setIsLoaded(false)
    const sessIDStoredInCookie = getCookie("DA-sessionID")
    const languageStoredInCookie = getCookie("DA-language")
    // await clearInterview(sessIDStoredInCookie)
    await postDAAction(sessIDStoredInCookie, "dq_legal", "")
    await fetchAPIData(sessIDStoredInCookie, languageStoredInCookie)
    setIsLoaded(true)
  }

  // Quickly find interpreting services
  const goToInterpreter = async (sessionID) => {
    setIsLoaded(false)
    const sessIDStoredInCookie = getCookie("DA-sessionID")
    const languageStoredInCookie = getCookie("DA-language")
    await postDAAction(sessIDStoredInCookie, "dq_interpret", "")
    await fetchAPIData(sessIDStoredInCookie, languageStoredInCookie)
    setIsLoaded(true)
  }

  const simplifiedFetchData = async (sessIDStoredInCookie, language) => {
    try {
      const urlToGetInterview = `${process.env.REACT_APP_DA_SERVER}` +
      `/api/session/question?key=${process.env.REACT_APP_DA_API_KEY}` +
      `&i=${process.env.REACT_APP_DA_INTERVIEW}` +
      `&session=${sessIDStoredInCookie}`

      const response = await fetch(urlToGetInterview)
      const json = await response.json()

      // document.title = `${json.browser_title}`
      document.title = translate("web-app-title")
      dispatch({ type: 'UPDATE_INTERVIEW_DATA', data: json })

      if (json.exit_link !== "exit") {
        document.cookie = "DA-language=" + json.lang + "; SameSite=Strict; Secure"
      } else {
        document.cookie = "DA-language=" + language + "; SameSite=Strict; Secure"
      }

      if (typeof(json.steps) !== "undefined") {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({page: json.steps}, "", "/page-" + json.steps)
        window.scrollTo(0, 0)
      }

    } catch (error) {
      setError(error)
      setIsLoaded(true)
    }
  }

  const fetchSessAndData = async (languageStoredInCookie) => {
    const sessionID = await newSessionID()
    // const languageStoredInCookie = getCookie("DA-language")
    await fetchAPIData(sessionID, languageStoredInCookie)
  }

  const restartInterview = async (e) => {
    if (e !== null && typeof(e) != "undefined") {
      e.preventDefault()
    }

    const sessIDStoredInCookie = getCookie("DA-sessionID")
    const languageStoredInCookie = getCookie("DA-language")
    setIsLoaded(false)

    // Delete current session
    const urlToDeleteInterview = `${process.env.REACT_APP_DA_SERVER}` +
      `/api/session?key=${process.env.REACT_APP_DA_API_KEY}` +
      `&i=${process.env.REACT_APP_DA_INTERVIEW}` +
      `&session=${sessIDStoredInCookie}`

    const response = await fetch(
      urlToDeleteInterview, {
        headers: {
          'Content-Type': 'application/json'
        },
        method: "DELETE"
      },
      {
        cache: "no-cache"
      }
    )

    if (response.ok && response.status === 204) {
      document.cookie = "DA-sessionID=null; SameSite=Strict; Secure"
      await fetchSessAndData(languageStoredInCookie)
      // Set number of times the interview has been restarted
    }
  }

  const handleClick = (e, multipleFields) => {
    e.preventDefault()
    setIsLoadedInApp(e.target.id)
    setShowButtonSpinnerWrapper()
    let stringifiedData
    const sessIDStoredInCookie = getCookie("DA-sessionID")

    if (e.currentTarget.id !== "multiple-fields-submit") {
    // Prepare the data
      stringifiedData = JSON.stringify({
        key: `${process.env.REACT_APP_DA_API_KEY}`,
        i: `${process.env.REACT_APP_DA_INTERVIEW}`,
        session: `${sessIDStoredInCookie}`,
        variables: {
          [`${e.target.name}`]: e.target.value,
        }
      })
    } else {
    // Prepare the data for multiple fields
      let fieldsList = {}
      multipleFields.map((multipleField) => {
        const fieldListItem = {[`${multipleField.name}`]: multipleField.value}
        fieldsList = {...fieldsList, ...fieldListItem}
        return true
      })

      stringifiedData = JSON.stringify({
        key: `${process.env.REACT_APP_DA_API_KEY}`,
        i: `${process.env.REACT_APP_DA_INTERVIEW}`,
        session: `${sessIDStoredInCookie}`,
        variables: {...fieldsList}
      })
    }

    const urlToPostInterview = `${process.env.REACT_APP_DA_SERVER}` +
      "/api/session"

    fetch(
        urlToPostInterview, {
          headers: {
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: stringifiedData,
        },
        {
          cache: "no-cache"
        }
      )
      .then(results => results.json())
      .then(
        (results) => {
          dispatch({
            type: 'UPDATE_INTERVIEW_DATA',
            data: results
          })
          setIsLoadedInApp(null)
          setShowButtonSpinner(false)
          // eslint-disable-next-line no-restricted-globals
          if (typeof(results.steps) !== "undefined") {
            // eslint-disable-next-line no-restricted-globals
            history.replaceState({ page: results.steps }, "", "/page-" + results.steps)
            window.scrollTo(0, 0)
          }
        },
        (error) => {
          setError(error)
          setIsLoadedInApp(null)
          setShowButtonSpinner(false)
        })
  }

  const goPrevQuestion = async (e, supercharged) => {
    let prevQuestion
    if (e !== null && typeof(e) != "undefined" && e !== "") {
      e.preventDefault()
    }
    setIsLoaded(false)
    if (supercharged !== null && typeof(supercharged) != "undefined") {
      // console.log("supercharged = ", `"${supercharged}"`)
      prevQuestion = await fetchPrevQuestion()
      console.log("prevQuestion.event_list[0] = ", prevQuestion.event_list[0] )
      while (prevQuestion.event_list[0] !== supercharged) {
        prevQuestion = await fetchPrevQuestion()
        console.log("prevQuestion.event_list[0] = ", prevQuestion.event_list[0] )
      }
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({ page: prevQuestion.steps}, "", "/page-" + prevQuestion.steps)
      window.scrollTo(0, 0)
    } else {
      prevQuestion = await fetchPrevQuestion()
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({ page: prevQuestion.steps}, "", "/page-" + prevQuestion.steps)
      window.scrollTo(0, 0)
    }
    setIsLoaded(true)
  }

  const fetchPrevQuestion = async () => {
    setSessionExpired(false)
    const urlToGoBack = `${process.env.REACT_APP_DA_SERVER}` +
      "/api/session/back"

    const sessIDStoredInCookie = getCookie("DA-sessionID")

    let stringifiedData = JSON.stringify({
    key:`${process.env.REACT_APP_DA_API_KEY}`,
      i: `${process.env.REACT_APP_DA_INTERVIEW}`,
      session: `${sessIDStoredInCookie}`
    })

    try {
      const response = await fetch(
        urlToGoBack, {
          headers: {
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: stringifiedData,
        },
        {
          cache: "no-cache"
        }
      )

      if (!response.ok) {
        if (response.status === 400 ) {
          setSessionExpired(true)
          throw new Error(`Your session has expired`)
        } else {
          setSessionExpired(false)
          throw new Error(`HTTP error! status: ${response.status}`)
        }
      }

      const json = await response.json()
      // Language on previous page may be different
      document.cookie = "DA-language=" + json.lang + "; SameSite=Strict; Secure"
      dispatch({
        type: 'UPDATE_INTERVIEW_DATA',
        data: json
      })
      return json
    } catch (error) {
      setError(error)
      setIsLoaded(true)
    }
  }

  if (needsToBeBlank) {
    return ( <BlankCanvas / > )
  }

  if (!isLoaded) {
    return ( < Spinner / > )
  }

  // Debug info
  const debugFlag = false
  const sessIDStoredInCookie = getCookie("DA-sessionID")
  const languageStoredInCookie = getCookie("DA-language")
  let debugInfo = ''
  if (debugFlag) {
    debugInfo = (
      <h1 className = "debug-info">
        Session id = {sessIDStoredInCookie}<br/>
        Language = {languageStoredInCookie}<br/>
        pseudoHistoryArray.current = {pseudoHistoryArray.current}<br/>
        pseudoHistoryArray.current.length = {pseudoHistoryArray.current.length}
      </h1>
    )
  }

  if (error && sessionExpired) {
    return (
      <p className = "font-semibold p-4">
       <span className = "text-brandPurple">{ error.message}</span>
      </p>
    )
  }

  if (error && !sessionExpired) {
    return (
      <p className = "font-semibold p-4">
       Oops! Something went wrong. < br/>
       <span className = "text-red-700">{ error.message}</span>
      </p>
    )
  }

  if (typeof(state) !== "undefined" && typeof(state.data) !== "undefined"
  && ((state.data.subquestionText === "" && state.data.questionText === "")
  || state.data.questionType === "restart") ) {
    return (
      <>
        {debugInfo}

        <ModalWindows
          interview={state.data}
          restartInterview={restartInterview}
        />
        <div>
          <div id="interview-background"></div>
          <LastPage
            interview={state.data}
            goPrevQuestion = {goPrevQuestion}
            restartInterview = {restartInterview}
          />
        </div>
      </>
    )
  }

  if (isLoaded) {
    return (
      <>
        {debugInfo}

        <ModalWindows
          interview={state.data}
          restartInterview={restartInterview}
        />

        <InterviewWrapper
          isLoadedInApp={isLoadedInApp}
          showButtonSpinner={showButtonSpinner}
          interview={state.data}
          sessionID={sessIDStoredInCookie}
          handleClick={handleClick}
          goPrevQuestion = {goPrevQuestion}
          restartInterview = {restartInterview}
        />
      </>
    )
  }
}

export default Pathway
