const translationsVi = {
  // ***************
  // * Framework *
  // ***************
  "web-app-title": "Lộ trình đến sống Vui sống Khỏe",
  "exit-help-modal-txt": "Hiểu rồi, cảm ơn.",
  "exit-generic-modal-txt": "Được rồi",
  "app-exit-message": "Cảm ơn bạn đã sử dụng Lộ trình đến sống Vui sống Khỏe của AVWA.",
  "app-exit-thankyou": "Bạn lại có thể sử dụng Lộ trình này khi bạn cần tìm gì khác.",
  "questions": "Câu hỏi",
  "prev-question-txt": "Câu hỏi trước",
  "prev-question-explain": "Quay lại màn hình trước đó.",
  "back-selection-txt": "Quay lại màn hình tìm kiếm",
  "back-selection-explain": "Quay trở lại màn hình tìm kiếm.",
  "quick-links": "Đường dẫn nhanh",
  "ql-mh-find": "Tìm các dịch vụ sức khỏe tâm thần",
  "ql-mh-find-explain": "Nhanh chóng tìm thấy các dịch vụ sức khỏe tâm thần miễn phí hoặc chi phí thấp.",
  "ql-legal-find": "Tìm dịch vụ pháp lý",
  "ql-legal-find-explain": "Nhanh chóng tìm thấy các dịch vụ pháp lý miễn phí hoặc giá cả phải chăng.",
  "ql-interpreter-find": "Tìm dịch vụ thông dịch",
  "ql-interpreter-find-explain": "Nhanh chóng tìm ra thời điểm và cách thức yêu cầu dịch vụ thông dịch miễn phí.",
  "find-out-more-txt": "Tìm hiểu thêm",
  "reload-txt": "Khởi động lại",
  "reload-explain": "Đi Lộ trình đến sống Vui sống Khỏe lại từ đầu.",
  "main-menu-txt": "Menu chính",
  "main-menu-explain": "Quay lại menu chính.",
  "da-main-menu": "Menu chính",
  "web-site-txt": "Đi tới trang chính của AVWA",
  "about": "Thông tin",
  "about-app": "Giới thiệu về Lộ trình đến sống Vui sống Khỏe",
  "about-app-desc": "Tìm hiểu thêm về Lộ trình đến sống Vui sống Khỏe này.",
  "privacy-txt": "Riêng tư",
  "privacy-desc": "Hiểu rõ hơn về cách chúng tôi bảo vệ quyền riêng tư của bạn.",
  "website-terms": "Điều khoản trang web",
  "website-terms-desc": "Đọc về các điều khoản áp dụng trên trang web của chúng tôi và các dịch vụ chúng tôi cung cấp.",
  "urgent-help": "Giúp đỡ khẩn cấp",
  "restart-confirm-header":"Khởi động lại",
  "restart-confirm-text":"Bạn có chắc chắn muốn bắt đầu Lộ trình đến sống Vui sống Khỏe lại từ đầu không?",
  "yes-answer": "Đúng",
  "no-answer": "Không",
  "OK-answer": "Tôi hiểu",
  "quick-exit": "Thoát nhanh",
  "quick-exit-short": "Lối ra",
  "continue-txt": "Tiếp tục",
  "previous-label": "Trước",
  "next-label": "Kế tiếp",
  "phone": "Điện thoại",
  "email": "E-mail",
  "website": "Trang web",
  "website-link": "Trang web",
  "address": "Địa chỉ",
  "service-type": "Loại dịch vụ",
  "walkins-badge-label": "Đến trực tiếp",
  "more-about": "Tìm hiểu thêm về ",
  "mht-label": "Tòa Tài phán Sức khỏe Tâm thần",
  "treatment-orders-label": "Án lệnh điều trị",
  "family-law-label": "Luật gia đình",
  "criminal-law-label": "Luật hình sự",
  "immigration-law-label": "Nhập cư và tị nạn",
  "tenancy-law-label": "Thuê nhà",
  "guardianship_adm-law-label": "Giám hộ và quản lý",
  "ndis-label": "Chương trình bảo hiểm khuyết tật quốc gia NDIS",
  "debts-label": "Nợ",
  "vietnamese-label": "Dịch vụ bằng tiếng Việt và tiếng Anh",
  "walkins-available": "Không cần đặt lịch <span class=\"hidden md:inline\"> bạn có thể đến trực tiếp</span>",
  "telehealth-badge-label": "Telehealth",
  "telehealth": "Qua điện thoại hoặc video<span class=\"hidden md:inline\"> (Telehealth)</span> lịch hẹn<span class=\"hidden md:inline\"> có sẵn</span>",

  // ***************
  // * Application *
  // ***************
  "alt-message-for-brand": "AVWA",
  "app-name-top-menu-item": "Lộ trình đến sống Vui sống Khỏe",
  "app-name-top-mi-short": "Lộ trình",
  "menu-header": "Thực đơn",

  // **************************
  // * Application Long Texts *
  // **************************
  // Urgent Help
  "urgent-help-text": `
    <p class="urgent-help">
       Trợ giúp có sẵn 24 giờ x 7 ngày một tuần:
     </p>
    <p class="urgent-help">
       <b>Cấp cứu, cảnh sát và xe cứu thương</b><br/>
       Gọi: <a href="tel:000">000</a><br/>
     </p>
    <p class="urgent-help">
       <b>Lifeline</b> - hỗ trợ khủng hoảng<br/>
       Gọi: <a href="tel:131114">13 11 14</a><br/>
       <a class="external-link" href="https://www.lifeline.org.au" target="_blank">
         lifeline.org.au
       </a>
     </p>
    <p class="urgent-help">
       <b>Suicide Callback Service</b> - Dịch vụ gọi lại về tự sát<br/>
       Gọi: <a href="tel:1300659467">1300 659 467</a><br/>
       <a class="external-link" href="https://www.suicidecallbackservice.org.au" target="_blank">
         suicidecallbackservice.org.au
       </a>
     </p>
    <p class="urgent-help">
       <b>SuicideLine Victoria</b> - Dịch vụ phòng chống tự sát<br/>
       Gọi: <a href="tel:1300651251">1300 651 251</a><br/>
       <a class="external-link" href="https://suicideline.org.au" target="_blank">
         suicideline.org.au
       </a>
     </p>
    <p class="urgent-help">
      <b>1800 RESPECT</b> - Đường dây trợ giúp về bạo hành gia đình và tấn công tình dục<br/>
      Gọi: <a href="tel:1800737732">1800 737 732</a><br/>
       <a class="external-link" href="https://1800respect.org.au" target="_blank">
        1800respect.org.au
       </a>
     </p>
    <p class="urgent-help">
       <b>Gambler's Help</b> - Trợ giúp người cờ bạc<br/>
      Gọi: <a href="tel:1800858858">1800 858 858</a><br/>
       <a class="external-link" href="https://gamblershelp.com.au" target="_blank">
         gamblershelp.com.au
       </a>
     </p>
     <p class="urgent-help pb-0">
       Nếu bạn không nói được tiếng Anh, bạn có thể gọi đến các dịch vụ này và yêu cầu một thông dịch viên.
       Dịch vụ thông dịch là miễn phí.
     </p>
     <p class="urgent-help pb-0">
      Hoặc bạn có thể gọi tới <b>Dịch vụ Thông dịch và Biên dịch (TIS)</b> miễn phí
      trên <a href="tel:131450">131&nbsp;450</a>:
       <ul class="text-brandOrangeDarker ml-4" style="list-style-type:square;">
       <li><span class="text-brandTextDark">nói ngôn ngữ của bạn</span></li>
       <li><span class="text-brandTextDark">yêu cầu liên hệ với dịch vụ bạn cần.</span></li>
       </ul>
     </p>`,

  // *******************************
  // * Mental Health Service Types *
  // *******************************
  // Mental Health and Wellbeing Local service
  "mh-and-wellbeing-local": `
    <p class="mb-2">
      <b>Mental Health and Wellbeing Local</b> - Trung tâm Sức khỏe Tâm thần và An sinh tại địa phương là những
      dịch vụ <b>miễn phí</b> nhằm giúp đỡ những người đang đối mặt với thách thức về sức khỏe tâm thần.
      Các trung tâm này cung cấp việc điều trị, chăm sóc và hỗ trợ miễn phí.
    </p>
    <p class="mb-2">
      Bạn không cần giấy giới thiệu từ bác sĩ, bạn không cần kế hoạch chăm sóc sức khỏe
      tâm thần hoặc thẻ Medicare để sử dụng dịch vụ.
    </p>
    <p class="mb-0">
      Các thành viên trong gia đình, người chăm sóc, bạn bè hoặc những người hỗ trợ cũng có
      thể nhận được sự giúp đỡ <b>miễn phí</b> cho bản thân họ.
    </p>`,

    // Primary Health Network
    "phn": `
      <p class="mb-2">
      	<b>Primary Health Networks (PHNs)</b> - Mạng Lưới Chăm sóc Sức khỏe Sơ cấp
      	giúp người dân tìm kiếm các dịch vụ chăm sóc sức khỏe tâm thần ở gần họ. Mạng lưới này liên kết các dịch vụ khác nhau, như bệnh viện, bác sĩ, cơ sở chăm sóc cao niên, và bảo đảm rằng mọi người đang nhận được chăm sóc cần thiết. Họ cũng cung cấp thông tin và tài nguyên.
      </p>
      <p class="mb-0">
      	Có 6 Mạng Lưới Chăm sóc Sức khỏe Cơ bản ở Victoria, mỗi mạng lưới bao quát một khu vực khác nhau.
      </p>`,

    // Mental Health and Wellbeing Hub
    "mh-and-wellbeing-hub": `
      <p class="mb-2">
        <b>Mental Health and Wellbeing Hubs</b> - Trung tâm Sức khỏe Tâm thần và An sinh là
        những dịch vụ miễn phí giúp đỡ những người đối mặt với thách thức về tâm lý. Bạn không
        cần thẻ Medicare hoặc giấy giới thiệu từ bác sĩ. Bạn có thể gọi để đặt lịch hẹn hoặc đến
        trực tiếp.
      </p>
      <p class="mb-0">
        Trung tâm Sức khỏe Tâm thần và An Sinh đang được thay thế bởi các dịch vụ Sức khỏe Tâm thần và An sinh tại địa phương, nhưng chúng vẫn hoạt động ở những khu vực mà dịch vụ địa phương chưa vận hành.
    </p>`,

    // headspace
    "headspace": `
      <p class="mb-2">
        <b>headspace</b> giúp đỡ những người trẻ tuổi từ 12-25 về sức khỏe tâm thần của họ. headspace cung cấp sự giúp đỡ trực tuyến và qua điện thoại miễn phí, cũng như các dịch vụ miễn phí hoặc giá thấp khác.
      </p>
      <p class="mb-0">
        Họ cũng hỗ trợ các người thân và người chăm sóc của những người đối mặt với thách thức về sức khỏe tâm thần.
      </p>`,

    // Family and Carer-Led Centre and Mental Health and Wellbeing Connect Centre
    "connect-centre": `
      <p class="mb-2">
        <b>Mental Health and Wellbeing Connect centres</b> - Trung tâm Kết nối Sức khỏe Tâm thần và An sinh
        là những địa điểm mà các người thân, người chăm sóc và những người hỗ trợ những người đối mặt với thách thức về sức khỏe tâm thần có thể đến để nhận sự hỗ trợ.
      </p>
      <p class="mb-0">
        Các trung tâm này là miễn phí và được điều hành bởi những người đã có kinh nghiệm thực tế và bất kỳ ai cũng có thể đến đây. Họ có thể cung cấp thông tin, giúp tìm kiếm tài nguyên và tiếp cận nguồn tiền hỗ trợ khó khăn.
      </p>`,

    // Community Mental Health Clinic
    "community-mh-clinic": `
      <p class="mb-2">
        Saltwater Clinic là một phòng mạch cộng đồng về sức khỏe tâm thần dành cho người trưởng thành,
        cung cấp dịch vụ chăm sóc cộng đồng về sức khỏe tâm thần cho những người sống tại các vùng Maribyrnong
        và Hobsons Bay. Phòng Mạch Saltwater chỉ hoạt động dựa trên lịch hẹn.
      </p>
      <p class="mb-0">
      Giới thiệu đến Phòng Mạch Saltwater được thực hiện thông qua Triage Tâm thần của
        Mercy Mental Health.
      </p>`,
  // *********
  // * Pages *
  // *********
  // Privacy Policy
  "privacy-heading": "Chính sách bảo mật của Hội Phụ Nữ Việt Úc",
  "privacy-text": `
    <div class="static-conent font-serif">
    <p class="my-2">
      Hội Phụ Nữ Việt Úc (AVWA) cam kết cung cấp
      dịch vụ chất lượng cho quý vị và chính sách này nêu rõ các nghĩa vụ hiện tại của chúng tôi
      với quý vị về cách chúng tôi quản lý thông tin cá nhân của quý vị.
    </p>
    <p>
      Bằng việc sử dụng các dịch vụ hoặc trang web của chúng tôi, quý vị đồng ý với việc thu thập, sử dụng,
      tiết lộ và chuyển giao thông tin cá nhân của quý vị như được nêu trong Chính sách bảo mật này.
    </p>
    <p class="mb-2">
      Chúng tôi đã áp dụng Nguyên tắc bảo mật riêng tư của Úc (Australian Privacy Principles (APPs)) nằm trong Đạo luật quyền riêng tư 1988 (Cth) (the Privacy Act). Các nguyên tắc này chi phối cách thức mà chúng tôi thu thập, sử dụng, tiết lộ, lưu trữ, bảo mật và xử lý thông tin cá nhân của quý vị.

    </p>
    <p class="mb-5">
      Quý vị có thể lấy bản sao Nguyên tắc quyền riêng tư của Úc từ
      trang web của Văn phòng Ủy viên Thông tin Úc (The Office of the Australian Information Commissioner ) tại
      <a class='regularLink'
      href='https://www.oaic.gov.au/privacy/australian-privacy-principles'
      target='_blank'>oaic.gov.au/privacy/australian-privacy-principles</a>.
    </p>
   <h2 class="font-sans text-lg text-brandBlue font-medium">
      Thông tin cá nhân là gì và tại sao chúng tôi thu thập nó?
    </h2>
    <p class="mb-2">
      Thông tin cá nhân là thông tin hoặc ý kiến ​​để xác định một cá nhân.
      Ví dụ về thông tin cá nhân mà chúng tôi thu thập bao gồm: tên, địa chỉ,
      địa chỉ email, số điện thoại và số fax.
    </p>
    <p class="mb-2">
      Thông tin cá nhân này được thu thập bằng nhiều cách bao gồm phỏng vấn,
      thư từ, qua điện thoại và fax, qua email, qua trang web của chúng tôi
      <b>avwa.org.au</b> và <b>avwa.legaltechhelper.com.au</b>,
      từ Microsoft Azure của chúng tôi, từ phương tiện truyền thông và ấn phẩm, từ các nguồn công khai khác,
      từ cookie và từ bên thứ ba.
      Chúng tôi không đảm bảo cho các trang web liên kết hoặc chính sách của bên thứ ba được ủy quyền.
    </p>
    <p class="mb-2">
      Nếu quý vị sử dụng trang web của chúng tôi, một số thông tin nhất định có thể được thu thập liên quan đến
      cách quý vị sử dụng trang web của chúng tôi. Điều này bao gồm các số nhận dạng Internet. Ví dụ,
      Địa chỉ IP và vị trí địa lý của trung tâm dữ liệu ISP của quý vị.
      Chúng tôi thu thập thông tin cá nhân của quý vị với mục đích chính là cung cấp
      dịch vụ cho quý vị, cung cấp thông tin cho khách hàng của chúng tôi và hoạt động tiếp thị.
      Chúng tôi cũng có thể sử dụng thông tin cá nhân của quý vị cho các mục đích phụ mà có liên quan
      chặt chẽ đến mục đích chính, trong những trường hợp này quý vị có thể tin rằng việc sử dụng hoặc tiết lộ như vậy là hợp lý.
      Quý vị có thể hủy đăng ký khỏi danh sách gửi thư/tiếp thị của chúng tôi bất cứ lúc nào bằng cách liên hệ với chúng tôi bằng văn bản.
    </p>
    <p class="mb-4">
      Khi chúng tôi thu thập thông tin cá nhân, chúng tôi sẽ, khi thích hợp và khi
      có thể, giải thích cho quý vị lý do tại sao chúng tôi thu thập thông tin và cách chúng tôi
      dự định sử dụng nó.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Thông tin nhạy cảm
    </h2>
    <p class="mb-2">
      Thông tin nhạy cảm được định nghĩa trong Đạo luật quyền riêng tư bao gồm thông tin
      hoặc quan điểm về những thứ như nguồn gốc chủng tộc hoặc dân tộc của một cá nhân,
      quan điểm chính trị, thành viên của một hiệp hội chính trị, tôn giáo hoặc
      niềm tin triết học, thành viên của một công đoàn hoặc các tổ chức nghề nghiệp khác
      tiền án tiền sự hoặc thông tin sức khỏe.
    </p>
    <p class="mb-2">
      Thông tin nhạy cảm sẽ chỉ được chúng tôi sử dụng:
    <ul lớp="mb-4">
      <li><span>Vì mục đích chính mà nó được thu thập</span></li>
      <li><span>Đối với mục đích phụ liên quan trực tiếp đến mục đích chính</span></li>
      <li><span>Với sự đồng ý của quý vị; hoặc khi pháp luật yêu cầu hoặc cho phép.</span></li>
    </ul>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Các bên thứ ba
    </h2>
    <p class="mb-4">
      Khi có thể hợp lý và khả thi, chúng tôi sẽ thu thập thông tin cá nhân của quý vị
      chỉ từ quý vị. Tuy nhiên, trong một số trường hợp chúng tôi có thể
      được thông tin từ bên thứ ba. Trong trường hợp như vậy chúng tôi sẽ tiến hành
      các bước hợp lý để bảo đảm rằng quý vị biết về những thông tin
      được cung cấp cho chúng tôi bởi bên thứ ba.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Chúng tôi sử dụng và tiết lộ thông tin cá nhân như thế nào?
    </h2>
    <p class="mb-2">
      Các mục đích mà chúng tôi có thể thu thập, sử dụng hoặc tiết lộ thông tin cá nhân của quý vị
      bao gồm:
    <ul>
      <li><span>
        để cung cấp dịch vụ của chúng tôi. Điều này bao gồm việc cung cấp cho quý vị lời khuyên, thông tin,
        nguồn lực hoặc giới thiệu đến các tổ chức khác. Điều này cũng bao gồm
        việc ngẫu nhiên sử dụng và tiết lộ xảy ra như một phần hoạt động của tổ chức chúng tôi;
      </span></li>
      <li><span>
        để liên lạc với quý vị. Ví dụ: để trả lời các thắc mắc của quý vị,
        các câu hỏi, yêu cầu hoặc khiếu nại liên quan đến dịch vụ của chúng tôi;
      </span></li>
      <li><span>
        để đánh giá các đơn xin việc và các vị trí tình nguyện tại AVWA; Và
      </span></li>
      <li><span>
        tuân thủ pháp luật hiện hành. Chúng tôi có thể sử dụng hoặc tiết lộ thông tin cá nhân của quý vị
        khi tuân thủ các nghĩa vụ pháp lý và quy định của chúng tôi;
      </span></li>
    </ul>
    </p>
    <p class="mb-2">
      Nếu quý vị không cung cấp cho chúng tôi những thông tin cá nhân được yêu cầu,
      chúng tôi có thể không cho quý vị tiếp cận các dịch vụ hoặc sự hỗ trợ của chúng tôi.
    </p>
    <p class="mb-2">
      Chúng tôi cũng có thể sử dụng thông tin cá nhân của quý vị theo hình thức ẩn danh, gộp lại hoặc
      không xác định. Ví dụ:
    <ul lớp="mb-4">
      <li><span>
        cho các trường hợp nghiên cứu ẩn danh. Nhân viên của chúng tôi có thể biên soạn trường hợp
        nghiên cứu ẩn danh dựa trên kinh nghiệm của khách hàng. Những thứ này được sử dụng để
        giúp giải thích các dịch vụ của chúng tôi và nêu bật nhu cầu của khách hàng;
      </span></li>
      <li><span>
        để tiến hành nghiên cứu dựa trên ứng dụng web Lộ trình đến sống Vui sống Khỏe (Pathways to Wellbeing) của chúng tôi.
        Phân tích sử dụng được thu thập về người dùng ứng dụng của trang web <b>Lộ trình đến sống Vui sống Khỏe</b>.
        Ví dụ: số lượng người dùng và phạm trù
        dịch vụ pháp luật hoặc sức khỏe tâm thần được xem. Thông tin này giúp chúng tôi
        xác định thông tin và dịch vụ có nhu cầu cao và xin thêm kinh phí; và
      </span></li>
      <li><span>
        tuân thủ các thỏa thuận tài trợ. Chúng tôi phải cung cấp
        một số thông tin không xác định danh tính cho các cơ quan tài trợ. Chúng tôi không cung cấp
        các cơ quan tài trợ có thông tin nhận dạng, chẳng hạn như tên hoặc địa chỉ của quý vị.
      </span></li>
      <li><span>
        để xác định các xu hướng và thay đổi về nhân khẩu học của những người truy cập
        dịch vụ của chúng tôi. Chúng tôi có thể sử dụng và tiết lộ thông tin không xác định danh tính để
        chúng tôi hiểu và truyền đạt thông tin nhân khẩu học của những người
        yêu cầu thông tin và dịch vụ của chúng tôi theo khu vực họ sống.
      </span></li>
    </ul>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Chúng tôi chia sẻ thông tin cá nhân của quý vị với ai?
    </h2>
    <p class="mb-2">
      Chúng tôi có thể chia sẻ thông tin cá nhân của quý vị với nhân viên, tình nguyện viên,
      nhà thầu hoặc nhà cung cấp dịch vụ nhằm mục đích cung cấp dịch vụ của chúng tôi.
    </p>
    <p class="mb-2">
      Một số bên thứ ba có thể truy cập thông tin cá nhân của quý vị. Bao gồm các:
    <ul lớp="mb-4">
      <li><span>
        các bên thứ ba mà chúng tôi dùng để có thể cung cấp dịch vụ của mình. Ví dụ,
        Quản trị viên hệ thống CNTT, máy chủ xử lý, chuyển phát nhanh,
        quản trị viên mạng điện tử và cố vấn chuyên nghiệp (ví dụ: kế toán viên
        và luật sư); và
      </span></li>
      <li><span>
        cơ quan chính phủ, cơ quan quản lý, cơ quan thực thi pháp luật hoặc sàn giao dịch
        hoặc tòa án. Chúng tôi chỉ làm điều này khi bị bắt buộc bởi
        luật hoặc quy định hiện hành hoặc theo yêu cầu của họ.
      </span></li>
    </ul>
    <p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Chúng tôi lưu trữ thông tin cá nhân của quý vị ở đâu?
    </h2>
    <p class="mb-4">
      Chúng tôi, bao gồm cả các nhà cung cấp dịch vụ của chúng tôi, có thể lưu giữ hồ sơ điện tử về
      thông tin cá nhân của quý vị bằng công nghệ đám mây, bằng các phương tiện điện tử khác,
      hoặc ở dạng giấy. Tất cả thông tin cá nhân điện tử mà chúng tôi nắm giữ là
      được lưu trữ trên các máy chủ đặt tại Úc. Tuy nhiên, trong quá trình sử dụng
      cookie và phân tích web trên trang web của chúng tôi, một số thông tin có thể được giữ lại hoặc
      được xử lý bên ngoài nước Úc, bao gồm Hoa Kỳ và Liên minh Châu Âu.
      Việc chúng tôi chỉ định trước vị trí của tất cả
      nhà cung cấp dịch vụ hoặc người dùng mà chúng tôi có giao dịch là không khả thi.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Tiết lộ thông tin cá nhân
    </h2>
    <p class="mb-2">
      Thông tin cá nhân của quý vị có thể được tiết lộ trong một số trường hợp bao gồm:
    <ul lớp="mb-4">
      <li><span>
        Các bên thứ ba nơi quý vị đồng ý cho sử dụng hoặc tiết lộ; và
      </span></li>
      <li><span>
        Trường hợp pháp luật yêu cầu hoặc cho phép.
      </span></li>
    </ul>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Bảo mật thông tin cá nhân
    </h2>
    <p class="mb-4">
      Thông tin cá nhân của quý vị được lưu trữ một cách hợp lý để được bảo vệ và
      tránh việc sử dụng sai và mất mát cũng như việc truy cập, sửa đổi hoặc tiết lộ trái phép.
      Khi thông tin cá nhân của quý vị không còn cần thiết cho mục đích
      mà nó đã được thu thập, chúng tôi sẽ thực hiện các bước hợp lý để tiêu hủy hoặc
      vĩnh viễn hủy nhận dạng thông tin cá nhân của quý vị. Tuy nhiên, hầu hết các
      thông tin cá nhân đang hoặc sẽ được lưu trữ trong các tập tin khách hàng sẽ được
      được chúng tôi lưu giữ tối thiểu 7 năm.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Truy cập vào thông tin cá nhân của quý vị
    </h2>
    <p class="mb-2">
     Quý vị có thể truy cập thông tin cá nhân mà chúng tôi lưu giữ về quý vị và cập nhật
      và/hoặc sửa nó, tùy theo một số ngoại lệ nhất định. Nếu quý vị muốn truy cập
      thông tin cá nhân của quý vị, vui lòng liên hệ với chúng tôi bằng văn bản.
    </p>
    <p class="mb-4">
      AVWA sẽ không tính bất kỳ khoản phí nào đối với yêu cầu truy cập của quý vị, nhưng có thể tính phí
      phí hành chính để cung cấp bản sao thông tin cá nhân của quý vị.
      Để bảo vệ thông tin cá nhân của quý vị, chúng tôi có thể yêu cầu
      giấy tờ tùy thân của quý vị trước khi tiết lộ thông tin được yêu cầu.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Duy trì chất lượng thông tin cá nhân của quý vị
    </h2>
    <p class="mb-4">
      Điều quan trọng đối với chúng tôi là thông tin cá nhân của quý vị được cập nhật.
      Chúng tôi sẽ thực hiện các bước hợp lý để bảo đảm rằng thông tin cá nhân của quý vị
      là chính xác, đầy đủ và cập nhật. Nếu quý vị thấy rằng thông tin
      chúng tôi có chưa cập nhật hoặc không chính xác, vui lòng thông báo cho chúng tôi ngay khi
      có thể để chúng tôi có thể cập nhật dữ liệu của mình và bảo đảm chúng tôi có thể tiếp tục
      cung cấp dịch vụ chất lượng cho quý vị.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Chúng tôi sử dụng cookie như thế nào?
    </h2>
    <p class="mb-2">
      Đôi khi, chúng tôi có thể sử dụng cookie hoặc các công cụ theo dõi khác trên trang web của mình
      (Trackers). Trackers cho phép chúng tôi truy cập và lưu trữ thông tin hoặc tài nguyên
      trên thiết bị của người dùng khi họ tương tác với trang web của chúng tôi. Một loại
      Tracker là cookie, là một tệp tin rất nhỏ mà một trang web sử dụng để
      nhận dạng quý vị khi quý vị quay lại trang web và lưu trữ thông tin chi tiết về
      việc quý vị sử dụng trang web.
    </p>
    <p class="mb-2">
      Trang web của chúng tôi sử dụng Google Analytics để phân tích lưu lượng truy cập.
      Nếu quý vị không đồng ý với việc sử dụng Trình theo dõi (Trackers) trên trang web của chúng tôi, vui lòng tắt
      chúng bằng cách làm theo hướng dẫn dành cho trình duyệt hoặc thiết bị của quý vị hoặc bằng cách sử dụng
      công cụ vô hiệu hóa tự động nếu có.
    </p>
    <p class="mb-4">
      Ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b> của chúng tôi sử dụng
      Cookie Matomo Analytics cho mục đích nghiên cứu. Những phân tích này sẽ
      nắm bắt thông tin, chẳng hạn như số lượng người dùng
      và các phạm trù về luật và dịch vụ sức khỏe tâm thần đã được xem.
      Ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b> của chúng tôi sử dụng cookie phiên (session cookie),
      là cookie chỉ tồn tại trong bộ nhớ tạm thời trong khi quý vị
      điều hướng ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b> của chúng tôi.
      Cookie phiên chạy hết hạn hoặc bị xóa khi quý vị đóng trình duyệt web.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Chính sách cập nhật
    </h2>
    <p class="mb-4">
      Chính sách này có thể thay đổi theo thời gian và có sẵn trên trang web của chúng tôi.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Khiếu nại và Thắc mắc về Chính sách Quyền Riêng tư
    </h2>
    <p class="mb-4">
      Nếu quý vị có bất kỳ thắc mắc hoặc khiếu nại nào về Chính sách quyền riêng tư của chúng tôi, vui lòng liên hệ với chúng tôi tại:
    <ul>
      <li><span>
          Địa chỉ: 30-32 Lennox Street, <span class="inline md:hidden"><br /></span>Richmond, VIC 3121
      </span></li>
      <li><span>
        E-mail:
        <a class='regularLink' href='mailto&#58;%6&#57;nfo&#64;%61v&#119;%61&#46;&#111;r%67%&#50;E&#97;u '>in&#102;o&#64;&#97;vw&#97;&#46;&#111;r&#103;&#46;au</a>
      </span></li>
      <li><span>
        Điện thoại:
        <a class='regularLink' href='tel:0394289078'>(03) 9428 9078</a>.
      </span></li>
    </ul>
    </p>
    <p class="mt-2" style="font-style: italic">
      Chính sách quyền riêng tư được cập nhật lần cuối vào ngày 12 tháng 2 năm 2024.
    </p>`,

  // About Page
  "about-heading": "Thông tin về Lộ trình đến sống Vui sống Khỏe",
  "about-text": `
    <div class="static-conent font-serif">
    <p class="mb-2">
      Ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b> mới của AVWA giúp người dân Victoria gốc Việt
      tìm hiểu thêm về hệ thống Sức khỏe Tâm thần Victoria mới.
    </p>
    <p class="mb-2">
      Bạn có thể sử dụng <b>Lộ trình đến sống Vui sống Khỏe</b> để hiểu các quyền của mình cũng như để
      tìm các dịch vụ sức khỏe tâm thần, dịch vụ pháp lý và dịch vụ thông dịch tại
      Victoria cho chính bạn hoặc ai đó bạn biết.
    </p>
    <p class="mb-2">
      <b>Lộ trình đến sống Vui sống Khỏe</b> không phải là tư vấn pháp lý và cũng không thể
       thay thế cho lời khuyên pháp lý.
    </p>
    <p class="mb-4">
      <b>Lộ trình đến sống Vui sống Khỏe</b> không đưa ra lời khuyên y tế.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Phiên bản
    </h2>
    <p class="mb-4">
      <b>Pathways to Wellbeing</b> (Lộ trình đến sống Vui sống Khỏe) - v1.0.0
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      Tín dụng
    </h2>
    <p class="mb-2">
      Được thiết kế và xây dựng bởi Legal Tech Helper.
    </p>
    <p class="mb-2">
      Nội dung bởi CQ University, Legal Tech Helper, Mental Health Legal Centre
      and Hội Phụ Nữ Việt Úc (AVWA).
    </p>
    <p class="mb-2">
      Đánh giá pháp lý bởi Mental Health Legal Centre.
    </p>
    <p class="mb-2">
      Thiết kế và đánh giá UX bởi Legal Tech Helper,
      Mental Health Legal Centre, Central Queensland University và Hội Phụ Nữ Việt Úc (AVWA).
    </p>
    <p class="mb-2">
      Minh họa bởi Tammy Winter Design.
    </p>
    <p class="mb-2">
    Cảm ơn nhóm dự án của chúng tôi vì sự hợp tác của họ:
    </p>
    <p class="my-1" style="margin-top: 0.25rem;">
       Hội Phụ Nữ Việt Úc (AVWA): Solomon Abbay, Huy Luu, Tuan Bui, The Ly;
    </p>
    <p class="my-1" style="margin-top: 0.25rem;">
      CQ University: Dr. Jacob Deem, Assoc Prof Huong Le, Dr. Luke Price, Dr. Victoria Lambropolous;
    </p>
    <p class="my-1" style="margin-top: 0.25rem;">
      Legal Tech Helper: Tatiana Lenz, Samantha Lovrich;
    </p>
    <p class="my-1" style="margin-top: 0.25rem;">
      Mental Health Legal Centre: Kate Windmill.
    </p>
    </div>`,

  // Website Terms Page
  "terms-heading": "Điều khoản và điều kiện của trang web",
  "terms-text": `
    <div class="static-conent font-serif">
    <p style="font-style: italic">
      Các Điều khoản trang web này áp dụng cho tất cả các cá nhân sử dụng trang web AVWA
      (bao gồm ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b>). Những thuật ngữ này giải thích:
    <ul style="font-style: italic">
      <li><span>
	chúng tôi là ai;
      </span></li>
      <li><span>
	các điều khoản được áp dụng như thế nào;
      </span></li>
      <li><span>
        các dịch vụ được cung cấp bởi Trang web của chúng tôi;
      </span></li>
      <li><span>
          các giới hạn của Trang web của chúng tôi;
      </span></li>
      <li><span>
          các yêu cầu và hạn chế khi sử dụng Trang web của chúng tôi;
      </span></li>
      <li><span>
          quyền sở hữu của chúng tôi đối với Trang web và nội dung của nó;
      </span></li>
      <li><span>
          cách chúng tôi xử lý thông tin cá nhân của quý vị;
      </span></li>
      <li><span>
          trách nhiệm của chúng tôi;
      </span></li>
      <li><span>
          quý vị có thể liên hệ với chúng tôi bằng cách nào; và
      </span></li>
      <li><span>
          các vấn đề chung khác.
      </span></li>
    </ul>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      1. Chúng tôi là ai?
    </h2>
    <p class="mb-4">
      Chúng tôi là Hội Phụ Nữ Việt Úc (AVWA). Chúng tôi là một cộng đồng
      tổ chức giúp đỡ người dân Victoria gốc Việt.
      Là một phần trong các dịch vụ của mình, chúng tôi vận hành trang web <b>avwa.org.au</b> của mình.
      Trang web này nhằm mục đích cung cấp thông tin tổng quát và thông tin
      về các dịch vụ và chương trình dành cho cộng đồng của chúng tôi.
      Trong các điều khoản và điều kiện của trang web này (Điều khoản trang web), việc tham chiếu đến
      "chúng tôi", "của chúng tôi" hoặc "của chúng tôi" đề cập đến AVWA.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      2. Những thuật ngữ này là gì?
    </h2>
    <p class="mb-2">
      Các Điều khoản trang web này giải thích các điều khoản và điều kiện áp dụng cho việc sử dụng
      của Trang web của chúng tôi và bất kỳ dịch vụ nào được cung cấp thông qua Trang web,
      chẳng hạn như ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b> tại <b>avwa.legaltechhelper.com.au</b>.
      Các điều khoản và điều kiện riêng biệt áp dụng cho các chương trình và dịch vụ cộng đồng của chúng tôi.
    </p>
    <p class="mb-4">
      Các Điều khoản trang web này bao gồm toàn bộ thỏa thuận giữa các bên
      liên quan đến việc sử dụng trang web của chúng tôi. Bất kỳ thỏa thuận nào trước đó hoặc
      hiểu biết liên quan đến việc sử dụng Trang web của chúng tôi được thay thế bằng những hiểu biết này
      Điều khoản trang web.
    </p>

    <h2 class="font-sans text-lg text-brandBlue font-medium">
      3. Những điều khoản này áp dụng cho ai?
    </h2>
    <p class="mb-2">
      Khi quý vị sử dụng Trang web của chúng tôi, các Điều khoản trang web này tạo thành một thỏa thuận giữa
      chúng tôi và quý vị. Các Điều khoản trang web này áp dụng cho bất kỳ ai sử dụng Trang web hoặc
      bất kỳ dịch vụ nào được cung cấp thông qua Trang web.
    </p>
    <p class="mb-4">
      Những điều khoản này không áp dụng cho các chương trình và dịch vụ cộng đồng của chúng tôi.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      4. Trang web này cung cấp những dịch vụ gì?
    </h2>
    <p class="mb-2">
      Trang web cung cấp:
    <ol>
      <li>
        (a) thông tin về các dịch vụ và chương trình dành cho cộng đồng của chúng tôi và;
      </li>
      <li>
        (b) một ứng dụng web cho phép quý vị tìm kiếm các yêu cầu pháp lý về sức khỏe tâm thần
        thông tin có thể liên quan đến quý vị (<b>Lộ trình đến sống Vui sống Khỏe</b>
        ứng dụng web). Ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b>
        bao gồm thông tin pháp lý và sức khoẻ tâm thần nói chung nhằm mục đích
        hỗ trợ người dân Victoria gốc Việt.
      </li>
    </ol>
    </p>
    <p class="mb-2">
      Nội dung và chức năng của Trang web có thể thay đổi theo thời gian.
    </p>
    <p class="mb-4">
      Trang web chứa các liên kết đến các trang web khác do bên thứ ba điều hành (Liên kết).
      Các Liên kết này được cung cấp chỉ nhằm mục đích thuận tiện và có thể phải tuân theo
      cập nhật, sửa đổi hoặc những thay đổi khác của bên thứ ba kiểm soát
      hoặc sở hữu những trang web đó. Chúng tôi không chịu trách nhiệm về hoạt động hoặc nội dung
      trên bất kỳ trang web của bên thứ ba nào được truy cập thông qua Liên kết.
      Quý vị truy cập vào các Liên kết phải tự chịu rủi ro.
    <p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      5. Những hạn chế của trang web này là gì?
    </h2>
    <p class="mb-2">
      Thông tin trên Trang web này chỉ mang tính chất thông tin chung.
      Trang web này, bao gồm cả thông qua ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b>,
      không cung cấp tư vấn pháp lý hoặc tư vấn y tế hoặc sức khỏe.
    </p>
    <p class="mb-2">
      Thông tin pháp lý trên Trang web này nhằm mục đích giải thích luật pháp và
      hệ thống pháp luật nói chung. Một số phần của Trang web này có thể cho phép
      quý vị tìm kiếm thông tin pháp lý bằng cách nhập thông tin chi tiết của quý vị. Tuy nhiên,
      thông tin pháp lý không phải là tư vấn pháp lý và không phù hợp với nhu cầu của trường hợp cụ thể của quý vị.
      Nếu quý vị cần tư vấn pháp lý, quý vị có thể liên hệ với luật sư.
    <p>
    <p class="mb-2">
      Theo như quyền hạn được quy định bởi luật pháp:
    <ol>
      <li>
        (a) Chúng tôi từ chối mọi sự đại diện và bảo đảm, bày tỏ,
        ngụ ý hoặc theo luật định, không được quy định rõ ràng trong các Điều khoản trang web này.
        Điều này bao gồm mọi bảo đảm ngụ ý về khả năng bán được, tính phù hợp
        cho một mục đích cụ thể và không vi phạm.
      </li>
      <li>
        (b) Chúng tôi không bảo đảm Trang web sẽ khả dụng hoặc việc quý vị sử dụng
        của Trang web sẽ không bị gián đoạn hoặc không có lỗi. Trang web
        được cung cấp cho quý vị trên cơ sở “nguyên trạng” và “có sẵn”.
      </li>
    </ol>
    </p>
    <p class="mb-4">
      Để rõ ràng, không có điều khoản nào trong các Điều khoản trang web này giới hạn hoặc loại trừ bất kỳ bảo đảm nào,
      sự đại diện hoặc điều kiện ngụ ý hoặc áp đặt bởi
      luật (bao gồm Luật Người tiêu dùng Úc) không thể bị giới hạn hoặc loại trừ.
    <p>

    <h2 class="font-sans text-lg text-brandBlue font-medium">
      6. Quý vị sử dụng trang web này như thế nào?
    </h2>
    <p class="mb-4">
      Trang web dựa vào truy cập internet và có thể không hoạt động với tất cả
      các trình duyệt internet. Chúng tôi không thể bảo đảm rằng Trang web sẽ hoạt động trong
      mọi điều kiện hoặc trên mọi thiết bị, bao gồm cả máy tính bảng và thiết bị di động.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      7. Những hạn chế nào áp dụng cho trang web này?
    </h2>
    <p class="mb-4">
      Quý vị không thể và không được cho phép bất kỳ người nào khác:
    <ol>
      <li>
        (a) sửa đổi, gở bỏ, dịch ngược, đảo ngược kỹ thuật hoặc
        cố gắng truy cập vào mã nguồn của bất kỳ phần nào của Trang web;
      </li>
      <li>
        (b) truyền tải bất kỳ nội dung nào có thể gây tổn hại, vô hiệu hóa, phá hủy hoặc
        can thiệp vào Trang web. Ví dụ: bất kỳ loại virus, worms, phần mềm gián điệp,
        hoặc phần mềm quảng cáo;
      </li>
      <li>
        (c) sử dụng Trang web theo cách ảnh hưởng đến
        việc vận hành tiêu chuẩn của Trang web hoặc việc người khác sử dụng Trang web; hoặc
      </li>
      <li>
        (d) sử dụng bất kỳ robot, spider, scraper hoặc phương tiện tự động nào khác để
        truy cập vào trang web.
      </li>
    </ol>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      8. Ai sở hữu trang web này?
    </h2>
    <p class="mb-2">
      Trang web này, bao gồm tất cả nội dung trên Trang web này, được sở hữu hoặc cấp phép
      bởi chúng tôi. Trừ khi được nêu rõ ràng trong các Điều khoản trang web này, việc quý vị sử dụng
      Trang web không cung cấp cho quý vị bất kỳ quyền nào đối với Trang web hoặc bất kỳ nội dung nào
      trên Trang web này.
    </p>
    <p class="mb-4">
      Chúng tôi cấp cho quý vị quyền sử dụng Trang web này, chỉ cho mục đích sử dụng cá nhân hoặc
      để giới thiệu khách hàng đến với chúng tôi. Quý vị có thể sử dụng Trang web này để lấy thông tin
      và yêu cầu hỗ trợ từ chúng tôi. Quý vị cấp cho chúng tôi quyền sử dụng và tích hợp bất kỳ đề xuất chung,
      yêu cầu nâng cao, khuyến nghị hoặc các phản hồi mà quý vị cung cấp cho chúng tôi vào trang web mà
      không trả phí, trên phạm vi toàn cầu, không thể hủy bỏ, và vĩnh viễn.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      9. Chúng tôi xử lý thông tin cá nhân của quý vị như thế nào?
    </h2>
    <p class="mb-4">
      Chúng tôi cố gắng hạn chế việc thu thập thông tin cá nhân thông qua Trang web của chúng tôi.
      Khi quý vị sử dụng ứng dụng web <b>Lộ trình đến sống Vui sống Khỏe</b>,
      một số thông tin không xác định danh tính có thể được thu thập dựa trên việc quý vị sử dụng
      ứng dụng <b>Lộ trình đến sống Vui sống Khỏe</b>. Điều này giúp chúng tôi
      hiểu về nhân khẩu học của những người yêu cầu thông tin pháp lý và
      các vấn đề quan trọng đối với khách hàng của chúng tôi. Nếu chúng tôi thu thập bất kỳ thông tin cá nhân nào
      thông tin từ quý vị trong khi quý vị đang sử dụng Trang web của chúng tôi, chúng tôi sẽ xử lý thông tin đó
      theo Chính sách quyền riêng tư của chúng tôi.
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      10. Trách nhiệm của chúng tôi đối với trang web này là gì?
    </h2>
    <p class="mb-4">
      Trong phạm vi được pháp luật cho phép, AVWA, nhân viên, nhà thầu và
      các nhà cung cấp dịch vụ:
    <ol>
      <li>
        (a) không chịu trách nhiệm pháp lý hoặc chịu trách nhiệm về hành động hoặc việc không hành động của
        quý vị hoặc bất kỳ cá nhân nào khác sử dụng Trang web của chúng tôi;
      </li>
      <li>
        (b) không chịu trách nhiệm về tính chính xác, phù hợp hoặc sự hiện cập của
        bất kỳ thông tin nào quý vị truy cập hoặc nhận được thông qua Trang web của chúng tôi; Và
      </li>
      <li>
        (c) không chịu trách nhiệm về việc quý vị sử dụng hoặc tin cậy vào Trang web hoặc
        bất kỳ thông tin nào trên trang web của chúng tôi. Không có gì trong các Điều khoản trang web này
        loại trừ hoặc loại bỏ bất kỳ trách nhiệm pháp lý nào mà không thể bị hạn chế hoặc loại trừ do pháp luật quy định (bao gồm cả Luật Người Tiêu Dùng).
      </li>
    </ol>
    </p>
    <h2 class="font-sans text-lg text-brandBlue font-medium">
      11. Các vấn đề khác
    </h2>
    <h2 class="mt-4 font-bold text-brandTextDark">
      11.1 Những luật nào áp dụng cho các Điều khoản trang web này?
    </h2>
    <p class="mb-4">
      Các Điều khoản trang web này được điều chỉnh bởi luật pháp của Victoria, Úc.
      Nếu tranh chấp phát sinh liên quan đến các Điều khoản trang web này, quý vị đồng ý rằng
      tranh chấp có thể được đưa ra trước tòa án ở Victoria, Australia.
    </p>
    <h2 class="mt-2 font-bold text-brandTextDark">
      11.2 Làm cách nào chúng tôi có thể thay đổi các Điều khoản trang web này?
    </h2>
    <p class="mb-4">
      Chúng tôi có thể thay đổi các Điều khoản trang web này bất kỳ lúc nào bằng cách cập nhật chúng trên trang web của chúng tôi.
      Những điều khoản trang web được cập nhật sẽ được áp dụng kể từ ngày
      được cập nhật trên trang web của chúng tôi.
    </p>
    <h2 class="mt-2 font-bold text-brandTextDark">
      11.3 Quý vị có thể liên hệ với chúng tôi bằng cách nào?
    </h2>
    <p class="mb-4">
      Quý vị có thể liên hệ với chúng tôi:
    <ul>
      <li><span>
        Bằng email:
        <a class='regularLink' href='mailto&#58;%6&#57;nfo&#64;%61v&#119;%61&#46;&#111;r%67%&#50;E&#97;u'>in&#102;o&#64;&#97;vw&#97;&#46;&#111;r&#103;&#46;au</a> <b>hoặc</b>
      </span></li>
      <li><span>
        Thông qua trang web của chúng tôi:
        <a class='regularLink' href='https://www.avwa.org.au/en/contact-us' target='_blank'>avwa.org.au/en/contact-us</a> <b>hoặc</b>
      </span></li>
      <li><span>
        Bằng điện thoại:
        <a class='regularLink' href='tel:0394289078'>(03) 9428 9078</a>.
      </span></li>
    </ul>
    </p>
    <p class="mt-2" style="font-style: italic">
      Điều khoản của trang web được cập nhật lần cuối vào ngày 12 tháng 2 năm 2024.
    </p>
    </div>`,
}

export default translationsVi
